import RegisterEmployeeService from './RegisterEmployeeService';
import update from '../../../helpers/update';
import history from '../../../utils/history';

export const REGISTER_EMPLOYEE_STARTED = "REGISTER_EMPLOYEE_STARTED";
export const REGISTER_EMPLOYEE_SUCCESS = "REGISTER_EMPLOYEE_SUCCESS";
export const REGISTER_EMPLOYEE_FAILED = "REGISTER_EMPLOYEE_FAILED";


const initialState = {
    list: {
        //data: [],
        error: false,
        loading: false
    },
}

export const getEmployeeData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());
        RegisterEmployeeService.registerEmployee(filter)
            .then((response) => {
              const pushUrl = "/company/employees";
              history.push(pushUrl);
              document.location.reload()

            })
            .catch((error) => {
                if(error.response.data ==="NO_PERMISSION"){
                    //console.log("Redirect___________", error.response.data);
                    // history.push('/broker/profile')
                    alert("Ваш дозвіл на брокерську діяльність не підтверджений")
                     }  
                dispatch(getListActions.failed());
            });
    }
}

export const registerEmployeeReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case REGISTER_EMPLOYEE_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case REGISTER_EMPLOYEE_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            //newState = update.set(newState, 'list.data', action.payload.employees);
            break;
        }
        case REGISTER_EMPLOYEE_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {

        return {
            type: REGISTER_EMPLOYEE_STARTED
        }
    },
    success: (data) => {

        return {
            type: REGISTER_EMPLOYEE_SUCCESS,
            //payload: data
        }
    },
    failed: (error) => {

        return {
            type: REGISTER_EMPLOYEE_FAILED
        }
    }
}


