import axios from 'axios';
import {serverUrl} from '../../../config';

export default class ArchiveOfContractsService{
    static getArchiveOfContracts(filter){
        const url=`${serverUrl}api/company/statistic/contracts`;
        return axios.post(url, filter);
    };
    static getContractDetails(contractId){
      const url=`${serverUrl}api/company/statistic/contract-details/${contractId}`;
      return axios.get(url);
  }
}
