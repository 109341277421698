import axios from "axios";
import {serverUrl} from "../../../config";
export default class PropositionInfoService {
    static getPropositionInfo(propositionId){
        return axios.get(serverUrl+'api/broker/home/get-proposition-and-contract-info/'+propositionId);
    }
    static removeContract(contractId){
        return axios.get(`${serverUrl}api/broker/home/remove-offer/${contractId}`)
    }
}
