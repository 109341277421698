import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash.get";

const LoginLayout = ({ children }) => {
  const { auth, roles } = children.props;
  let redirectTo = "/login";

  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === "Client") redirectTo = "/client/profile";
    else if (roles[i] === "Broker") redirectTo = "/broker/profile";
    else if (roles[i] === "Admin") redirectTo = "/admin/clients";
  }
  return auth ? <Redirect to={ redirectTo } /> : <Redirect to="/login" />;
};

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, roles } = rest;
  return (
    <Route
      { ...rest }
      render={ matchProps => (
        <LoginLayout>
          <Component { ...matchProps } auth={ isAuthenticated } roles={ roles } />
        </LoginLayout>
      ) }
    />
  );
};

const mapStateToProps = state => {
  ////console.log("---map props----", state);
  return {
    isAuthenticated: get(state, "auth.isAuthenticated"),
    roles: get(state, "auth.user.roles")
  };
};

export default connect(mapStateToProps)(LoginLayoutRoute);
