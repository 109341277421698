import update from "../../../helpers/update";
import history from "../../../utils/history";
import ComplaintServices from "./ComplaintServices";

export const SEND_COMPLAINT_STARTED = "complaintClient/SEND_COMPLAINT_STARTED";
export const SEND_COMPLAINT_SUCCESS = "complaintClient/SEND_COMPLAINT_SUCCESS";
export const SEND_COMPLAINT_FAILED = "complaintClient/SEND_COMPLAINT_FAILED";


const initialState = {
    loading: false,
    failed: false,
    success: false,
}

export const complaintClientReducer = (state = initialState, action) => {
    let newState = state;
    switch (action.type) {
        case SEND_COMPLAINT_STARTED: {
            newState = update.set(state, "loading", true);
            newState = update.set(newState, "failed", false);
            newState = update.set(newState, "success", false);
            break;
        }
        case SEND_COMPLAINT_SUCCESS: {
            newState = update.set(state, "loading", false);
            newState = update.set(newState, "failed", false);
            newState = update.set(newState, "success", true);
            break;
        }
        case SEND_COMPLAINT_FAILED: {
            newState = update.set(state, "loading", false);
            newState = update.set(newState, "failed", true);
            newState = update.set(newState, "success", false);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;

};


export const sendClientComplaint=model=>{
    return dispatch =>{
        dispatch(ComplaintActions.started());
        ComplaintServices.sendClientComplaint(model)
        .then(()=>{
            dispatch(ComplaintActions.success());
            history.push("/client/profile");
            document.location.reload()

        })
        .catch(()=>dispatch(ComplaintActions.failed()))
    }
}
// export const sendBrokerComplaint=model=>{
//     return dispatch =>{
//         dispatch(ComplaintActions.started());
//         ComplaintServices.sendBrokerComplaint(model)
//         .then(()=>{
//             dispatch(ComplaintActions.success());
//             history.push("/broker/profile");
//         })
//         .catch(()=>dispatch(ComplaintActions.failed()))
//     }
// }

export const ComplaintActions = {
    started: () => {
        return {
            type: SEND_COMPLAINT_STARTED
        };
    },
    success: () => {
        return {
            type: SEND_COMPLAINT_SUCCESS,
        };
    },

    failed: () => {
        return {
            type: SEND_COMPLAINT_FAILED,
        };
    }
}