import axios from 'axios';
import {serverUrl} from "../../../config";
export default class ComplaintServices{
    static sendClientComplaint(model){
        const url = `${serverUrl}api/client/account/send-complaint`;
        return axios.post(url, model);
    }
    static sendBrokerComplaint(model){
        const url = `${serverUrl}api/broker/home/send-complaint`;
        return axios.post(url, model);
    }
}