import update from "../../../helpers/update";
import AdvertsService from "./AdvertsService";
import redirectStatusCode from '../../../services/redirectStatusCode';
 import history from '../../../utils/history';
export const GET_ADVERTS_STARTED = "adverts/GET_ADVERTS_STARTED";
export const GET_ADVERTS_SUCCESS = "adverts/GET_ADVERTS_SUCCESS";
export const GET_ADVERTS_FAILED = "adverts/GET_ADVERTS_FAILED";

const initialState = {
  list: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false,
  },
  totalAdvertsCount: 0,
  currentPage: 1,
  typeOfSort: 'default',
  sortByAscending: 'false',
};

export const advertsReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_ADVERTS_STARTED: {
      newState = update.set(state, "list.loading", true);
      newState = update.set(newState, "list.failed", false);
      newState = update.set(newState, "list.success", false);
      newState = update.set(newState, "list.error", {});

      break;
    }
    case GET_ADVERTS_SUCCESS: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.failed", false);
      newState = update.set(newState, "list.success", true);
      newState = update.set(newState, "list.data", action.payload.itemModel);
      newState = update.set(newState, "currentPage", action.payload.currentPage);
      newState = update.set(newState, "totalAdvertsCount", action.payload.totalAdvertsCount );
      newState = update.set(newState, "typeOfSort", action.payload.typeOfSort);
      newState = update.set(newState, "sortByAscending", action.payload.sortByAscending);
      newState = update.set(newState, "sortByAscending", action.payload.sortByAscending);
      newState = update.set(newState, "list.error", {});


      // newState = update.set(newState, )
      // newState = update.set(newState, )

      break;
    }
    case GET_ADVERTS_FAILED: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.failed", true);
      newState = update.set(newState, "list.success", false);
      newState = update.set(newState, "list.error", action.error);
      break;
    }
    default: {
      return newState;
    }
  }
  return newState;

};

export const getAdvertsData = sort => {
  return dispatch => {
    dispatch(AdvertsActions.started());
    AdvertsService.getAdverts(sort)
      .then(response => {
        dispatch(AdvertsActions.success(response));
      }, error => {throw error;})
      .catch(error => {
        dispatch(AdvertsActions.failed(error.response));
        redirectStatusCode(error.response.status);
      });
  };
};

export const AdvertsActions = {
  started: () => {
    return {
      type: GET_ADVERTS_STARTED
    };
  },

  success: data => {
    //////console.log("Payload Data: ", data.data);
    return {
      type: GET_ADVERTS_SUCCESS,

      payload: data.data
    };
  },

  failed: response => {
    return {
      type: GET_ADVERTS_FAILED,
      error: response.data
    };
  }
};
