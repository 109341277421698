import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import * as getBrokersActions from "./views/reducer";

var interval = null;
class ColotunWorker extends Component {
    constructor (props) {
        super(props);
        this.state = {
            login: this.props.login,
            timer: null
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            login: nextProps.login
        });
        this.sarik(nextProps.login);

    }

    componentWillUnmount() {
        this.sarik(null);
    }

    componentDidMount() {
        this.sarik(this.state.login);
    }
    sarik = (login) => {
        const { isAuthenticated } = login;
        if (isAuthenticated) {

            const { roles } = login.user;
            if (Array.isArray(roles)) {

                for (let i = 0; i < roles.length; i++) {
                    if (roles[i] === "Broker") {
                        interval = setInterval(() => {
                            this.props.getDataBroker();
                            this.props.getAdvertsCount();
                            // //console.log("Broker");
                        }, 10000);
                        break;
                    } else if (roles[i] === "Client") {
                        interval = setInterval(() => {
                            this.props.getDataClient();
                            this.props.getUnconfirmed();
                            // this.props.getConfirmed();

                            // //console.log("Client");

                        }, 10000);
                        break;
                    }
                }
            } else {
                if (roles === "Broker") {
                    interval = setInterval(() => {
                        this.props.getDataBroker();
                        this.props.getAdvertsCount();
                        // //console.log("Broker2");

                    }, 10000);
                } else if (roles === "Client") {
                    interval = setInterval(() => {
                        this.props.getDataClient();
                        this.props.getUnconfirmed();

                        //  //console.log("Client2");

                    }, 10000);
                }
            }

        }
        else {
            ////console.log("semen--------------------------");
            clearInterval(interval);
            //this.timer = null;
        }
    }

    render() {
        return (
            <></>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: get(state, 'login')
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getDataBroker: () => {
            dispatch(getBrokersActions.getDataBroker());
        },
        getDataClient: () => {
            dispatch(getBrokersActions.getDataClient());
        },
        getAdvertsCount: () => {
            dispatch(getBrokersActions.getAdvertsCount());
        },
        getUnconfirmed: () => {
            dispatch(getBrokersActions.getUnconfirmed());
        },
        getConfirmed: () => {
            dispatch(getBrokersActions.getConfirmed());
        }
    }
}
ColotunWorker.propTypes =
{
    login: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ColotunWorker);
