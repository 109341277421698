import PermissionService from "./PermissionService";
import update from '../../../helpers/update';


export const GET_PERMISSION_SUCCESS = "admin/GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_STARTED = "admin/GET_PERMISSION_STARTED";
export const GET_PERMISSION_FAILED = "admin/GET_PERMISSION_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false
    }
}

export const getPermissionData = () => {
    return (dispatch) => {
        dispatch(getListActions.started());

         PermissionService.getPermission()
            .then((response) => {
              ////console.log("response", response);
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const permissionSearchReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case GET_PERMISSION_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case GET_PERMISSION_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.brokers);
            break;
        }
        case GET_PERMISSION_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: GET_PERMISSION_STARTED
        }
    },
    success: (data) => {
      ////console.log("DATA : ",data);
        return {
            type: GET_PERMISSION_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: GET_PERMISSION_FAILED
        }
    }
}



