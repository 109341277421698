import update from "../../../helpers/update";
import redirectStatusCode from "../../../services/redirectStatusCode";
import ProfileService from "./ProfileService";

export const GET_CLIENT_PROFILE_STARTED = "clientProfile/GET_CLIENT_PROFILE_STARTED";
export const GET_CLIENT_PROFILE_SUCCESS = "clientProfile/GET_CLIENT_PROFILE_SUCCESS";
export const GET_CLIENT_PROFILE_FAILED = "clientProfile/GET_CLIENT_PROFILE_FAILED";

export const EDIT_CLIENT_PASSWORD_STARTED = "clientProfile/EDIT_CLIENT_PASSWORD_STARTED";
export const EDIT_CLIENT_PASSWORD_SUCCESS = "clientProfile/EDIT_CLIENT_PASSWORD_SUCCESS";
export const EDIT_CLIENT_PASSWORD_FAILED = "clientProfile/EDIT_CLIENT_PASSWORD_FAILED";

export const EDIT_CLIENT_PROFILE_STARTED = "clientProfile/EDIT_CLIENT_PROFILE_STARTED";
export const EDIT_CLIENT_PROFILE_SUCCESS = "clientProfile/EDIT_CLIENT_PROFILE_SUCCESS";
export const EDIT_CLIENT_PROFILE_FAILED = "clientProfile/EDIT_CLIENT_PROFILE_FAILED";

export const CLIENT_CHANGE_SEND_EMAIL_STARTED = "brokerProfile/CLIENT_CHANGE_SEND_EMAIL_STARTED";
export const CLIENT_CHANGE_SEND_EMAIL_SUCCESS = "brokerProfile/CLIENT_CHANGE_SEND_EMAIL_SUCCESS";
export const CLIENT_CHANGE_SEND_EMAIL_FAILED = "brokerProfile/CLIENT_CHANGE_SEND_EMAIL_FAILED";

export const CHANGE_CLIENT_IMAGE = "clientProfile/CHANGE_CLIENT_IMAGE";

const initialState = {
  profile: {
    data: {},
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  editPass: {
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  editProfile: {
    error: {},
    success: false
  },
  chageSendEmail: {
    error: {},
    failed: false,
    success: false
  },
  editImage: {
    //data: "",
    success: false
  }
};

export const clientProfileReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_CLIENT_PROFILE_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "profile.error", {});
      newState = update.set(newState, "editImage.success", false);
      newState = update.set(newState, "editPass.success", false);

      break;
    }
    case GET_CLIENT_PROFILE_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "profile.success", true);
      newState = update.set(newState, "profile.data", action.payload);
      newState = update.set(newState, "profile.error", {});

      break;
    }
    case GET_CLIENT_PROFILE_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", true);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "profile.error", action.error);

      break;
    }
    case EDIT_CLIENT_PASSWORD_STARTED: {
      newState = update.set(state, "editPass.loading", true);
      newState = update.set(newState, "editPass.failed", false);
      newState = update.set(newState, "editPass.success", false);
      newState = update.set(newState, "editPass.error", {});

      break;
    }
    case EDIT_CLIENT_PASSWORD_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "editPass.loading", false);
      newState = update.set(newState, "editPass.failed", false);
      newState = update.set(newState, "editPass.success", true);
      // newState = update.set(newState, "editPass.data", action.payload);
      newState = update.set(newState, "editPass.error", {});

      break;
    }
    case EDIT_CLIENT_PASSWORD_FAILED: {
      newState = update.set(state, "editPass.loading", false);
      newState = update.set(newState, "editPass.failed", true);
      newState = update.set(newState, "editPass.success", false);
      newState = update.set(newState, "editPass.error", action.error);

      break;
    }
    case EDIT_CLIENT_PROFILE_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "editProfile.error", {});

      break;
    }
    case EDIT_CLIENT_PROFILE_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "editProfile.success", true);
      // newState = update.set(newState, "editPass.data", action.payload);
      newState = update.set(newState, "editProfile.error", {});

      break;
    }
    case EDIT_CLIENT_PROFILE_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", true);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "editProfile.error", action.error);
      break;
    }

    case CLIENT_CHANGE_SEND_EMAIL_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "chageSendEmail.failed", false);
      newState = update.set(newState, "chageSendEmail.success", false);
      newState = update.set(newState, "chageSendEmail.error", {});

      break;
    }
    case CLIENT_CHANGE_SEND_EMAIL_SUCCESS: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "chageSendEmail.failed", false);
      newState = update.set(newState, "chageSendEmail.success", true);
      newState = update.set(newState, "chageSendEmail.error", {});

      break;
    }
    case CLIENT_CHANGE_SEND_EMAIL_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "chageSendEmail.failed", true);
      newState = update.set(newState, "chageSendEmail.success", false);
      newState = update.set(newState, "chageSendEmail.error", action.error);

      break;
    }

    case CHANGE_CLIENT_IMAGE: {
        // newState = update.set(state, "editImage.data", action.payload);
         newState = update.set(state, "editImage.success", true);
         break;
       }
    default: {
      return newState;
    }
  }

  return newState;
};

const fetchProfile = dispatch => {
  dispatch(ProfileActions.started());
  ProfileService.getClientProfile()
    .then(
      response => {
        dispatch(ProfileActions.success(response));
      },
      error => {
        throw error;
      }
    )
    .catch(error => {
      dispatch(ProfileActions.failed(error.response));
      redirectStatusCode(error.response.status);
    });
};
export const getProfile = () => {
  return dispatch => {
    fetchProfile(dispatch);
  };
};

export const editClientPassword = (oldPassword, newPassword) => {
  return dispatch => {
    dispatch(EditClientPasswordActions.started());
    ProfileService.editClientPassword(oldPassword, newPassword)
      .then(() => {
        dispatch(EditClientPasswordActions.success());
      })
      .catch(error => {
        dispatch(EditClientPasswordActions.failed(error.response));
        //redirectStatusCode(error.response.status);
      });
  };
};
export const changeSendEmailStatus = (sendEmailDisabled) => {
  return dispatch => {
    dispatch(ChangeSendEmailActions.started());
    ProfileService.changeSendEmailStatus(sendEmailDisabled)
      .then(() => {
        dispatch(getProfile());
        dispatch(ChangeSendEmailActions.success());
      })
      .catch(error => {
        dispatch(ChangeSendEmailActions.failed(error.response));
      });
  };
};

export const editClientProfile = (email, phoneNumber) => {
    return dispatch => {
      dispatch(EditClientProfileActions.started());
      ProfileService.editClientProfile(email, phoneNumber)
        .then(() => {
          dispatch(EditClientProfileActions.success());
          //fetchProfile(dispatch);
        })
        .catch(error => {
          dispatch(EditClientProfileActions.failed(error.response));
          //redirectStatusCode(error.response.status);
        });
    };
  };

export const editClientImage = (data) => {
    return dispatch => {
        ProfileService.editClientImage(data)
        .then(response=>{
            dispatch(EditClientImageActions.success(response));
            fetchProfile(dispatch);
        })
    }
}

export const ProfileActions = {
  started: () => {
    return {
      type: GET_CLIENT_PROFILE_STARTED
    };
  },
  success: response => {
    return {
      type: GET_CLIENT_PROFILE_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_CLIENT_PROFILE_FAILED,
      error: response
      // error: response.data
    };
  }
};

export const EditClientPasswordActions = {
  started: () => {
    return {
      type: EDIT_CLIENT_PASSWORD_STARTED
    };
  },
  success: () => {
    return {
      type: EDIT_CLIENT_PASSWORD_SUCCESS
    };
  },
  failed: response => {
    return {
      type: EDIT_CLIENT_PASSWORD_FAILED,
      error: response.data
    };
  }
};

export const EditClientProfileActions = {
    started: () => {
      return {
        type: EDIT_CLIENT_PROFILE_STARTED
      };
    },
    success: () => {
      return {
        type: EDIT_CLIENT_PROFILE_SUCCESS
      };
    },
    failed: response => {

      return {
        type: EDIT_CLIENT_PROFILE_FAILED,
        error: response.data
      };
    }
  };

  export const EditClientImageActions ={
    success: response => {
      //////console.log("Payload Data: ", response.data);
      return {
        type: CHANGE_CLIENT_IMAGE,
        payload: response.data
      };
    },
  }

  export const ChangeSendEmailActions = {
    started: () => {
      return {
        type: CLIENT_CHANGE_SEND_EMAIL_STARTED
      };
    },
    success: response => {
      return {
        type: CLIENT_CHANGE_SEND_EMAIL_SUCCESS
      };
    },
  
    failed: response => {
      return {
        type: CLIENT_CHANGE_SEND_EMAIL_SUCCESS,
        error: response.data
      };
    }
  };
  
