import PermissionConfirmedService from "./PermissionConfirmedService";
import update from '../../../helpers/update';
import history from "../../../utils/history";


export const PERMISSION_SUCCESS = "admin/PERMISSION_SUCCESS";
export const PERMISSION_STARTED = "admin/PERMISSION_STARTED";
export const PERMISSION_FAILED = "admin/PERMISSION_FAILED";

const initialState = {
        broker: {},
        error: false,
        loading: false
}

export const confirmedPermissionData = (brokerId) => {
  return (dispatch) => {
      dispatch(getListActions.started());

      PermissionConfirmedService.ConfirmedPermission(brokerId)
          .then(
             response => {
            const pushUrl = "/admin/permissions/";
            //////console.log("----PushUrl----", pushUrl);
            history.push(pushUrl);
            document.location.reload()

          },
          err => {
            throw err;
          })
          .catch(() => {
              dispatch(getListActions.failed());
          });
  }
}

export const getPermissionData = (brokerId) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        PermissionConfirmedService.getLicense(brokerId)
            .then((response) => {
              ////console.log("response", response.data);
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const permissionConfirmedReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case PERMISSION_STARTED: {
            newState = update.set(state, 'loading', true);
            break;
        }
        case PERMISSION_SUCCESS: {
            newState = update.set(state, 'loading', false);
            newState = update.set(newState, 'broker', action.payload);
            break;
        }
        case PERMISSION_FAILED: {
            newState = update.set(state, 'loading', false);
            newState = update.set(newState, 'error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: PERMISSION_STARTED
        }
    },
    success: (data) => {
      ////console.log("DATA : ",data);
        return {
            type: PERMISSION_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: PERMISSION_FAILED
        }
    }
}



