import ChartRegisterClientService from "./ChartRegisterClientService";
import update from '../../../helpers/update';

export const GET_CHART_CLIENT_SUCCESS = "admin/GET_CHART_CLIENT_SUCCESS";
export const GET_CHART_CLIENT_STARTED = "admin/GET_CHART_CLIENT_STARTED";
export const GET_CHART_CLIENT_FAILED = "admin/GET_CHART_CLIENT_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false
    },
}

export const getChartClientData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        ChartRegisterClientService.getChartRegisterClient(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const chartRegisterClientReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case GET_CHART_CLIENT_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case GET_CHART_CLIENT_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.clients);
            break;
        }
        case GET_CHART_CLIENT_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: GET_CHART_CLIENT_STARTED
        }
    },
    success: (data) => {
        return {
            type: GET_CHART_CLIENT_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: GET_CHART_CLIENT_FAILED
        }
    }
}

