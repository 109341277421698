import axios from 'axios';
import { serverUrl } from '../../../config';

export default class CompleatedOffersService {
    static getCompleatedOffers(filter) {
        const url = `${serverUrl}api/admin/compleatedoffers/search`;
        return axios.post(url, filter);
    }

    static getContracts(filter) {
        const url = `${serverUrl}api/admin/compleatedoffers/search-contracts`;
        return axios.post(url, filter);
    }
}
