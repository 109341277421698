import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./store/configureStore";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as loginActions from "./components/Pages/Login/reducer";
import jwt from "jsonwebtoken";

import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";

const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

if (localStorage.jwtToken) {
  const token = jwt.decode(localStorage.getItem('jwtToken'));
  const refreshThreshold = (Math.round(Date.now() / 1000));
  if (token && refreshThreshold > token.exp) {
    loginActions.logoutByJWT(store.dispatch);

      history.push("/login");
      document.location.reload()

  }
  let data = {
    token: localStorage.jwtToken,
    refToken: localStorage.refreshToken,
  };
  let user = jwt.decode(data.token);
  if (!Array.isArray(user.roles)) {
    user.roles = Array.of(user.roles);
  }

  loginActions.loginByJWT(data, store.dispatch);
}

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
