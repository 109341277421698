import React, { Component } from 'react';
//import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Route, Switch, Router } from "react-router";

import './App.scss';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoginLayout from './containers/LoginLayout';
import ColotunWorker from './ColotunWorker';
import EclipseWidget from './components/eclipse';

import i18n from "./locale/i18n";

const loading = () => <EclipseWidget />;

// Containers
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const BrokerLayout = React.lazy(() => import('./containers/BrokerLayout'));
const ClientLayout = React.lazy(() => import('./containers/ClientLayout'));
const CompanyLayout = React.lazy(() => import('./containers/CompanyLayout'));


// Pages
const Login = React.lazy(() => import('./components/Pages/Login'));
const RegisterCompany = React.lazy(() => import('./components/Pages/RegisterCompany'));
const RegisterBroker = React.lazy(() => import('./components/Pages/RegisterBroker'));
const RegisterClient = React.lazy(() => import('./components/Pages/RegisterClient'));
const ForgotPassword = React.lazy(() => import('./components/Pages/ForgotPassword'));
const Terms = React.lazy(() => import('./components/Pages/TermsOfUsePage/Terms'));
const Agreement = React.lazy(() => import('./components/Pages/TermsOfUsePage/Agreement'));
const AboutUs = React.lazy(() => import('./components/Pages/AboutUs'));
const Page404 = React.lazy(() => import('./components/Pages/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500'));
// const HomePage = React.lazy(() => import('./components/Pages/HomePage'));
const HomePage = React.lazy(() => import('./components/Pages/Home'));

const ClientInfoPage = React.lazy(() => import('./components/Pages/ClientInfoPage'));
const BrokerInfoPage = React.lazy(() => import('./components/Pages/BrokerInfoPage'));
const CompanyInfoPage = React.lazy(() => import('./components/Pages/CompanyInfoPage'));

const baseRouteUrl = "/:locale(uk|ru)?";
export const baseUrl = i18n.language === "en" ? "" : "/" + i18n.language;

class App extends Component {
  render() {
    return (
      <>
        <React.Suspense fallback={ loading() }>
          <Switch>
            <Route exact path={baseRouteUrl + "/"} name="Home" render={ props => <HomePage { ...props } /> } />
            <Route exact path={baseRouteUrl + "/client-info"} name="ClientInfoPage" render={ props => <ClientInfoPage { ...props } /> } />
            <Route exact path={baseRouteUrl + "/broker-info"} name="BrokerInfoPage" render={ props => <BrokerInfoPage { ...props } /> } />
            <Route exact path={baseRouteUrl + "/company-info"} name="CompanyInfoPage" render={ props => <CompanyInfoPage { ...props } /> } />
            <Route exact path={baseRouteUrl + "/login"} name="Login Page" render={ props => <Login { ...props } /> } />
            <Route exact path={baseRouteUrl + "/forgot-password"} name="Forgot Password" render={ props => <ForgotPassword { ...props } /> } />
            <Route exact path={baseRouteUrl + "/register/broker"} name="RegisterBroker Page" render={ props => <RegisterBroker { ...props } /> } />
            <Route exact path={baseRouteUrl + "/register/company"} name="RegisterCompany Page" render={ props => <RegisterCompany { ...props } /> } />
            <Route exact path={baseRouteUrl + "/register/client"} name="RegisterClient Page" render={ props => <RegisterClient { ...props } /> } />
            <Route exact path={baseRouteUrl + "/404"} name="Page 404" render={ props => <Page404 { ...props } /> } />
            <Route exact path={baseRouteUrl + "/500"} name="Page 500" render={ props => <Page500 { ...props } /> } />
            <Route path={baseRouteUrl + "/admin"} name="Admin" render={ props => <AdminLayout { ...props } /> } />
            <Route path={baseRouteUrl + "/broker"} name="Broker" render={ props => <BrokerLayout { ...props } /> } />
            <Route path={baseRouteUrl + "/client"} name="Client" render={ props => <ClientLayout { ...props } /> } />
            <Route path={baseRouteUrl + "/company"} name="Company" render={ props => <CompanyLayout { ...props } /> } />
            <Route path={baseRouteUrl + "/about"} name="About Us" render={ props => <AboutUs { ...props } /> } />
            <Route path={baseRouteUrl + "/terms"} name="Terms of use" render={ props => <Terms { ...props } /> } />
            <Route path={baseRouteUrl + "/agreement"} name="Agreement" render={ props => <Agreement { ...props } /> } />
            <LoginLayout exact path={baseRouteUrl + "/"} name="LoginPage" component={ Login } />
          </Switch>
        </React.Suspense>
        <ColotunWorker />
      </>
    );
  }
}



export default App;
