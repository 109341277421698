import ChartContractsService from "./ChartContractsService";
import update from '../../../helpers/update';

export const GET_CHART_CONTRACTS_SUCCESS = "admin/GET_CHART_CONTRACTS_SUCCESS";
export const GET_CHART_CONTRACTS_STARTED = "admin/GET_CHART_CONTRACTS_STARTED";
export const GET_CHART_CONTRACTS_FAILED = "admin/GET_CHART_CONTRACTS_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false,
    }
}

export const getChartContractsData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        ChartContractsService.getChartContracts(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const chartContractsReducer=(state=initialState, action) =>{
    let newState = state;
    switch (action.type) {
        case GET_CHART_CONTRACTS_STARTED: {
            newState = update.set(state, 'list.loading', true);
            newState = update.set(newState, 'list.success', false);
            break;
        }
        case GET_CHART_CONTRACTS_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.contracts);
            newState = update.set(newState, 'list.success', true);
            break;
        }
        case GET_CHART_CONTRACTS_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: GET_CHART_CONTRACTS_STARTED
        }
    },
    success: (data) => {
        return {
            type: GET_CHART_CONTRACTS_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: GET_CHART_CONTRACTS_FAILED
        }
    }
}
