export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_NEW_FULLNAME = 'SET_NEW_FULLNAME';

export const SET_CLIENT_PROFILE = 'SET_CLIENT_PROFILE';
export const CREATE_IMAGE_CLIENT_STARTED = 'client/CREATE_IMAGE_CLIENT_STARTED';  //Початок зміни фото для клієнта
export const CREATE_IMAGE_CLIENT_SUCCESS = 'client/CREATE_IMAGE_CLIENT_SUCCESS';  //Зміна фото успішна
export const CREATE_IMAGE_CLIENT_FAILED = 'client/CREATE_IMAGE_CLIENT_FAILED';  //Зміна фото прошла погано
export const SET_CLIENT_IMAGE = 'SET_CLIENT_IMAGE';  //Зміна фото в клієнта
export const SET_CLIENT_ADVERTS = 'SET_CLIENT_ADVERTS';
export const SET_CLIENT_CONTRACTS = 'SET_CLIENT_CONTRACTS';

export const SET_CLIENT_ADVERT = 'SET_CLIENT_ADVERT';
export const SET_CLIENT_ADVERT_IMAGES = 'SET_CLIENT_ADVERT_IMAGES';
export const SET_CLIENT_ADVERT_OFFERS = 'SET_CLIENT_ADVERT_OFFERS';
export const SET_CLIENT_ADVERTS_QUANTITY = 'SET_CLIENT_ADVERTS_QUANTITY';
export const SET_APPROVED_OFFER_INFO = 'SET_APPROVED_OFFER_INFO';
export const SET_OFFERS_FOR_ADVERT_QUANTITY = 'SET_OFFERS_FOR_ADVERT_QUANTITY';
// export const UNCHECK_IMAGE_POOR_QUALITY = 'UNCHECK_IMAGE_POOR_QUALITY';
export const UPDATE_CLIENT_IMAGE = 'UPDATE_CLIENT_IMAGE';
export const ADD_ADVERT = 'ADD_ADVERT';
export const SET_ADVERTS_INFO = 'SET_ADVERTS_INFO';
export const SET_CLIENT_REJECT_CONTRACTS = 'SET_CLIENT_REJECT_CONTRACTS';

export const UPDATE_BROKER_IMAGE = 'UPDATE_BROKER_IMAGE';
export const SET_BROKER_PROFILE = 'SET_BROKER_PROFILE';
export const SET_BROKER_CONTACT_INFO = 'SET_BROKER_CONTACT_INFO';

export const SET_BROKERS_LIST = 'SET_BROKERS_LIST';
export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST';
export const SET_COMPLETED_ADVERTS = 'SET_COMPLETED_ADVERTS';
export const UPDATE_ADMIN_IMAGE = 'UPDATE_ADMIN_IMAGE';
export const SET_ADMIN_PROFILE = 'SET_ADMIN_PROFILE';
