import axios from 'axios';
import {serverUrl} from '../../../config';

export default class RegisterEmployeesService{
    static registerEmployee(filter){
        const url=`${serverUrl}api/company/account/register-employee`;
        ////console.log("!!!!!!!1   Service");
        return axios.post(url, filter);
    }
}
