import { SET_CLIENTS_LIST, SET_BROKERS_LIST, SET_COMPLETED_ADVERTS,
         SET_ADMIN_PROFILE } from '../actions/types';

const initialState = {
    listClients: [],
    listBrokers:[],
    totalClientsCount: 0,
    totalBrokersCount: 0,
    currentPageClient: 0,
    currentPageBroker: 0,
    completedAdverts: [],
    totalAdvertsCount: 0,
    currentPage: 0,
    profile: {
      firstName: '',
      phoneNumber: '',
      image: '',
  }
};

export default function admin(state = initialState, action = {}) {
    switch (action.type) {
        case SET_CLIENTS_LIST:
                return Object.assign({}, state, { listClients: action.listClients,
                    totalClientsCount: action.totalClientsCount,
                    currentPageClient: action.currentPageClient});

        case SET_BROKERS_LIST:
                return Object.assign({}, state, { listBrokers: action.listBrokers,
                    totalBrokersCount: action.totalBrokersCount,
                    currentPageBroker: action.currentPageBroker});

        case SET_COMPLETED_ADVERTS:
                return Object.assign({}, state, { completedAdverts: action.completedAdverts,
                    totalAdvertsCount: action.totalAdvertsCount,
                    currentPage: action.currentPage});

        case SET_ADMIN_PROFILE:
                return Object.assign({}, state, {
                    profile: action.profile});
        default:
            return state;
    }
}
