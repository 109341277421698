import update from "../../../helpers/update";
import redirectStatusCode from "../../../services/redirectStatusCode";
import history from "../../../utils/history";
import ConfirmationService from "./ConfirmationService";

export const GET_OFFER_STARTED = "offer/GET_OFFER_STARTED";
export const GET_OFFER_SUCCESS = "offer/GET_OFFER_SUCCESS";
export const GET_OFFER_FAILED = "offer/GET_OFFER_FAILED";

export const GET_OFFER_TYPES_STARTED = "offer/GET_OFFER_TYPES_STARTED";
export const GET_OFFER_TYPES_SUCCESS = "offer/GET_OFFER_TYPES_SUCCESS";
export const GET_OFFER_TYPES_FAILED = "offer/GET_OFFER_TYPES_FAILED";

export const SEND_OFFER_STARTED = "offer/SEND_OFFER_STARTED";
export const SEND_OFFER_SUCCESS = "offer/SEND_OFFER_SUCCESS";
export const SEND_OFFER_FAILED = "offer/SEND_OFFER_FAILED";
export const SEND_OFFER_SHOW = "offer/SEND_OFFER_SHOW";

const initialState = {
  list: {
    data: {
      id: 0,
      images: [],
      submissionDate: new Date(),
      client: {
        clientName: "",
        clientImage: "",
      },
    },
    error: {},
    loading: false,
    failed: false,
    success: false,
  },
  offer: {
    loading: false,
    failed: false,
    success: false,
    error: {},
    sendFormSuccess: false,
  },
  offerTypes:{
    loading: false,
    failed: false,
    success: false,
    error: {},
    list:[]
  },
};

export const offerReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_OFFER_STARTED: {
      newState = update.set(state, "list.loading", true);
      newState = update.set(newState, "list.failed", false);
      newState = update.set(newState, "list.success", false);
      newState = update.set(newState, "list.error", {});
      break;
    }
    case GET_OFFER_SUCCESS: {
      // ////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.failed", false);
      newState = update.set(newState, "list.success", true);
      newState = update.set(newState, "list.data", action.payload);
      newState = update.set(newState, "list.error", {});

      break;
    }
    case GET_OFFER_FAILED: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.failed", true);
      newState = update.set(newState, "list.success", false);
      newState = update.set(newState, "list.error", action.error);

      break;
    }
    case SEND_OFFER_STARTED: {
      newState = update.set(state, "offer.loading", true);
      newState = update.set(newState, "offer.failed", false);
      newState = update.set(newState, "offer.success", false);
      newState = update.set(newState, "offer.error", {});
      break;
    }
    case SEND_OFFER_SUCCESS: {
      newState = update.set(state, "offer.loading", false);
      newState = update.set(newState, "offer.failed", false);
      newState = update.set(newState, "offer.success", true);
      newState = update.set(newState, "offer.sendFormSuccess", true);
      newState = update.set(newState, "offer.error", {});
      break;
    }
    case SEND_OFFER_SHOW: {
      newState = update.set(state, "offer.sendFormSuccess", false);
      break;
    }
    case SEND_OFFER_FAILED: {
      newState = update.set(state, "offer.loading", false);
      newState = update.set(newState, "offer.failed", true);
      newState = update.set(newState, "offer.success", false);
      newState = update.set(newState, "offer.error", action.error);
      break;
    }
    case GET_OFFER_TYPES_STARTED: {
      newState = update.set(state, "offerTypes.loading", true);
      newState = update.set(newState, "offerTypes.failed", false);
      newState = update.set(newState, "offerTypes.success", false);
      newState = update.set(newState, "offerTypes.error", {});
      break;
    }
    case GET_OFFER_TYPES_SUCCESS: {
      newState = update.set(state, "offerTypes.loading", false);
      newState = update.set(newState, "offerTypes.failed", false);
      newState = update.set(newState, "offerTypes.success", true);
      newState = update.set(newState, "offerTypes.list", action.payload);
      newState = update.set(newState, "offerTypes.error", {});
      break;
    }
    case GET_OFFER_TYPES_FAILED: {
      newState = update.set(state, "offerTypes.loading", false);
      newState = update.set(newState, "offerTypes.failed", true);
      newState = update.set(newState, "offerTypes.success", false);
      newState = update.set(newState, "offerTypes.error", action.error);
      break;
    }
    default: {
      return newState;
    }
  }
  return newState;
};

export const getAdvertForOffer = (advertId) => {
  return (dispatch) => {
    dispatch(OfferActions.started());
    ConfirmationService.getAdvert(advertId)
      .then(
        (response) => {
          dispatch(OfferActions.success(response));
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        if (error.response.data === "NO_PERMISSION") {
          ////console.log("Redirect");
          history.push("/broker/profile");
          alert("Ваш дозвіл на брокерську діяльність не підтверджений");
          document.location.reload()

        }
        // //console.log(error.response.data);
        dispatch(OfferActions.failed(error.response));
        redirectStatusCode(error.response.status);
      });
  };
};
export const getOfferTypes = () => {
  return (dispatch) => {
    dispatch(GetOfferTypesActions.started());
    ConfirmationService.getOfferTypes()
      .then(
        (response) => {
          dispatch(GetOfferTypesActions.success(response));
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        dispatch(GetOfferTypesActions.failed(error.response));
        redirectStatusCode(error.response.status);
      });
  };
};
export const sendOffer = (model) => {
  return (dispatch) => {
    dispatch(SendOfferActions.started());
    ConfirmationService.sendOffer(model)
      .then(
        () => {
          dispatch(SendOfferActions.success());
          setTimeout(() => {
            dispatch(SendOfferActions.show_check_abort());
            history.push("/broker/propositions/sended");
            document.location.reload()

          }, 2000);
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        //////console.log("error.response. ", error.response.data)
        dispatch(SendOfferActions.failed(error.response.data));
        redirectStatusCode(error.response.status);
      });
  };
};

export const SendOfferActions = {
  started: () => {
    return {
      type: SEND_OFFER_STARTED,
    };
  },
  success: () => {
    return {
      type: SEND_OFFER_SUCCESS,
    };
  },
  show_check_abort: () => {
    return {
      type: SEND_OFFER_SHOW,
    };
  },
  failed: (response) => {
    //////console.log("response.data+++++++", response)
    return {
      type: SEND_OFFER_FAILED,

      error: response,
    };
  },
};

export const OfferActions = {
  started: () => {
    return {
      type: GET_OFFER_STARTED,
    };
  },
  success: (resp) => {
    //////console.log("Payload Data: ", resp.data);
    return {
      type: GET_OFFER_SUCCESS,
      payload: resp.data,
    };
  },

  failed: (response) => {
    // ////console.log("OfferActions", response)
    return {
      type: GET_OFFER_FAILED,
      error: response.data,
    };
  },
};

export const GetOfferTypesActions = {
  started: () => {
    return {
      type: GET_OFFER_TYPES_STARTED,
    };
  },
  success: (resp) => {
    return {
      type: GET_OFFER_TYPES_SUCCESS,
      payload: resp.data,
    };
  },
  failed: (response) => {
    return {
      type: GET_OFFER_TYPES_FAILED,
      error: response.data,
    };
  },
};
