import axios from "axios";
import { serverUrl } from "../../../config";

export default class ProfileService {
  static getClientProfile() {
    const url = `${serverUrl}api/client/account/get-profile`;
    return axios.get(url);
  }
  static editClientPassword(oldPassword, newPassword) {
    const data = { oldPassword, newPassword };
    const url = `${serverUrl}api/client/account/change-password`;
    return axios.post(url, data);
  }
  static editClientProfile(email, phoneNumber) {
    const data = { email, phoneNumber };
    const url = `${serverUrl}api/client/account/edit-profile`;
    return axios.post(url, data);
  }
  static changeSendEmailStatus(sendEmailDisabled){
    const data = {sendEmailDisabled};
    const url = `${serverUrl}api/client/account/change-send-email-status`;
    return axios.post(url, data);
  }
  static editClientImage(data) {
    const url = `${serverUrl}api/client/account/change-image`;
    return axios.post(url, data);
  }
}
