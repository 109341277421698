import axios from 'axios';
import {serverUrl} from '../../../config';

export default class PermissionService{
    static getPermission(){
        const url=`${serverUrl}api/admin/broker/permission`;
        return axios.get(url);
    };

}
