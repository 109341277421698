import ArchiveOfOffersService from "./ArchiveOfOffersService";
import update from '../../../helpers/update';


export const ARCHIVE_OFFERS_SUCCESS = "ARCHIVE_OFFERS_SUCCESS";
export const ARCHIVE_OFFERS_STARTED = "ARCHIVE_OFFERS_STARTED";
export const ARCHIVE_OFFERS_FAILED = "ARCHIVE_OFFERS_FAILED";
export const OFFER_DETAILS_SUCCESS = "OFFER_DETAILS_SUCCESS";
export const OFFER_DETAILS_STARTED = "OFFER_DETAILS_STARTED";
export const OFFER_DETAILS_FAILED = "OFFER_DETAILS_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false
    },

    details: {
      data: {},
      error: false,
      loading: false
    },

    year: '',
    month: '',
}

export const getArchiveOfOffersData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());
        ArchiveOfOffersService.getArchiveOfOffers(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const getOfferDetailsData = (filter) => {
  ////console.log("FILTER   Filter");
  return (dispatch) => {
      dispatch(getDetailsActions.started());
      ArchiveOfOffersService.getOfferDetails(filter)
          .then((response) => {
              dispatch(getDetailsActions.success(response.data));
          })
          .catch(() => {
              dispatch(getDetailsActions.failed());
          });
  }
}

export const archiveOfOffersReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case ARCHIVE_OFFERS_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case ARCHIVE_OFFERS_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.offers);
            break;
        }
        case ARCHIVE_OFFERS_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
      case OFFER_DETAILS_STARTED: {
        newState = update.set(state, 'details.loading', true);
        break;
      }
      case OFFER_DETAILS_SUCCESS: {
        newState = update.set(state, 'details.loading', false);
        newState = update.set(newState, 'details.data', action.payload);
        break;
      }
      case OFFER_DETAILS_FAILED: {
        newState = update.set(state, 'details.loading', false);
        newState = update.set(newState, 'details.error', true);
        break;
      }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {

        return {
            type: ARCHIVE_OFFERS_STARTED
        }
    },
    success: (data) => {

        return {
            type: ARCHIVE_OFFERS_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {

        return {
            type: ARCHIVE_OFFERS_FAILED
        }
    }
}

export const getDetailsActions = {
  started: () => {

      return {
          type: OFFER_DETAILS_STARTED
      }
  },
  success: (data) => {
////console.log("getDetailsActions :", data);
      return {
          type: OFFER_DETAILS_SUCCESS,
          payload: data
      }
  },
  failed: (error) => {

      return {
          type: OFFER_DETAILS_FAILED
      }
  }
}



