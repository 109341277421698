import axios from 'axios';
import {serverUrl} from '../../../../config';

export default class AddAdverService {
    static addImage(data) {
        const url = `${serverUrl}api/client/account/addAdvert-image`;
        return axios.post(url, data);
    };
    static deleteImage(id) {
        const url = `${serverUrl}api/client/account/deleteAdvert-image/${id}`;
        return axios.delete(url, id);
    }

}
