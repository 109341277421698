import axios from "axios";
import { serverUrl } from "../../../../config";

export default class AdvertListService {
  static getAdverts() {
    const url = `${serverUrl}api/client/account/get-adverts`;
    return axios.get(url);
  }
  static getContracts() {
    const url = `${serverUrl}api/client/account/get-contracts`;
    return axios.get(url);
  }

  static getConfirmedContracts() {
    const url = `${serverUrl}api/client/account/get-finish-contracts`;
    return axios.get(url);
  }
  // static getRejectContracts() {
  //   const url = `${serverUrl}api/client/account/get-reject-adverts`;
  //   return axios.get(url);
  // }

  static rePublishAdvert(advertId, contractId){
    const url = `${serverUrl}api/client/account/re-publish/${advertId}/${contractId}`;
    return axios.get(url);
  }
}
