import ChartsRegisterBrokerService from "./ChartsRegisterBrokerService";
import update from '../../../helpers/update';

export const GET_CHART_BROKER_SUCCESS = "admin/GET_CHART_BROKER_SUCCESS";
export const GET_CHART_BROKER_STARTED = "admin/GET_CHART_BROKER_STARTED";
export const GET_CHART_BROKER_FAILED = "admin/GET_CHART_BROKER_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false,
    }
}

export const getChartBrokerData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        ChartsRegisterBrokerService.getChartRegisterBroker(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const chartRegisterBrokerReducer=(state=initialState, action) =>{
    let newState = state;
    switch (action.type) {
        case GET_CHART_BROKER_STARTED: {
            newState = update.set(state, 'list.loading', true);
            newState = update.set(newState, 'list.success', false);
            break;
        }
        case GET_CHART_BROKER_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.brokers);
            newState = update.set(newState, 'list.success', true);
            break;
        }
        case GET_CHART_BROKER_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: GET_CHART_BROKER_STARTED
        }
    },
    success: (data) => {
        return {
            type: GET_CHART_BROKER_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: GET_CHART_BROKER_FAILED
        }
    }
}
