import axios from 'axios';
import {serverUrl} from '../../../config';

export default class ArchiveOfOffersService{
    static getArchiveOfOffers(filter){
        const url=`${serverUrl}api/company/statistic/offers`;
        //console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
        return axios.post(url, filter);
    };
    static getOfferDetails(offerId){
      const url=`${serverUrl}api/company/statistic/offer-details/${offerId}`;
      return axios.get(url);
  }
}
