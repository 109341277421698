import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
//import { createBrowserHistory } from 'history';
//import createHistory from 'history/createHashHistory';
import {createBrowserHistory} from "history";

import client from '../reducers/client';
//import auth from "../reducers/auth";
// import broker from '../reducers/broker';
import admin from '../reducers/admin';
import { captchaReducer } from "../components/captcha/reducer";
import { addAdvertReducer } from "../components/client/advert/add-advert/reducer";
import { propositionInfoReducer } from "../components/broker/propositionInfo/reducer";
import { contractInfoReducer } from "../components/broker/contractInfo/reducer";
import { propostionsAndContractsReducer } from "../components/broker/proposition/reducer";
import { clientSearchReducer } from "../components/adminViews/Clients/reducer";
import { brokerSearchReducer } from "../components/adminViews/Brokers/reducer";
import { permissionSearchReducer } from "../components/adminViews/Permissions/reducer";
import { permissionConfirmedReducer } from "../components/adminViews/PermissionConfirmed/reducer";
import { chartRegisterClientReducer } from "../components/adminViews/ChartsRegisterClient/reducer";
import { chartRegisterBrokerReducer } from "../components/adminViews/ChartsRegisterBroker/reducer";
import { compleatedOffersReducer } from "../components/adminViews/CompleatedOffers/reducer";
import { compleatedOfferDetailsReducer } from "../components/adminViews/CompleatedOfferDetails/reducer";
import { chartContractsReducer } from "../components/adminViews/ChartContracts/reducer";
import { loginReducer } from "../components/Pages/Login/reducer";
import { refreshReducer } from '../components/refreshToken/reducer';
import { advertsReducer } from '../components/broker/adverts/reducer';
import { offerReducer } from '../components/broker/offer/reducer';
import { brokerProfileReducer } from "../components/broker/profile/reducer";
import { clientProfileReducer } from "../components/client/profile/reducer";
import { offersReducer } from "../components/adminViews/Offers/reducer";
import { offerDetailsReducer } from "../components/adminViews/OfferDetails/reducer";
import { homeReducer } from "../components/Pages/Home/redux/reducer";

import refreshTokenMiddleware from './middleware/refreshTokenMiddleware';
import { employeesReducer } from '../components/company/Employees/reducer';
import { registerEmployeeReducer } from '../components/company/RegisterEmployees/reducer';
import { archiveOfOffersReducer } from '../components/company/ArchiveOfOffers/reducer';
import { archiveOfContractsReducer } from '../components/company/ArchiveOfContracts/reducer';
import { advertDetailsReducer } from "../components/client/advert/advert-details/reducer";
import { listAdvertsReducer } from "../components/client/advert/adverts-list/reducer";
import { brokerBellReducer } from "../views/reducer";
import { complaintClientReducer } from "../components/client/complaint/reducer";
import { complaintBrokerReducer } from "../components/broker/complaint/reducer";


// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl });

export default function configureStore(history, initialState) {
  const reducers = {
    client,
    //auth,
    //broker,
    admin,
    captcha: captchaReducer,
    addAdvert: addAdvertReducer,
    propositionInfo: propositionInfoReducer,
    contractInfo: contractInfoReducer,
    propostionsAndContracts: propostionsAndContractsReducer,
    clientSerch: clientSearchReducer,
    brokerSearch: brokerSearchReducer,
    chartClient: chartRegisterClientReducer,
    chartBroker: chartRegisterBrokerReducer,
    chartContracts: chartContractsReducer,
    login: loginReducer,
    refreshToken: refreshReducer,
    companyEmployeers: employeesReducer,
    offer: offerReducer,
    brokerProfile: brokerProfileReducer,
    clientProfile: clientProfileReducer,
    adverts: advertsReducer,
    registerEmployee: registerEmployeeReducer,
    archiveOfOffers: archiveOfOffersReducer,
    archiveOfContracts: archiveOfContractsReducer,
    advertDetails: advertDetailsReducer,
    advertsList: listAdvertsReducer,
    permissions: permissionSearchReducer,
    permissionConfirmed: permissionConfirmedReducer,
    compleatedOffers: compleatedOffersReducer,
    compleatedOfferDetails: compleatedOfferDetailsReducer,
    offers: offersReducer,
    offerdetails: offerDetailsReducer,
    brokerBell: brokerBellReducer,
    complaintBroker: complaintBrokerReducer,
    complaintClient: complaintClientReducer,
    home:homeReducer
  };

  const middleware = [
    refreshTokenMiddleware(),
    thunk,
    routerMiddleware(history)
  ];

  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
