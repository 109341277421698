import update from "../../../helpers/update";
import redirectStatusCode from "../../../services/redirectStatusCode";
import ProfileService from "./ProfileService";
//import { dispatch } from "rxjs/internal/observable/pairs";

export const GET_BOKER_PROFILE_STARTED = "brokerProfile/GET_BOKER_PROFILE_STARTED";
export const GET_BOKER_PROFILE_SUCCESS = "brokerProfile/GET_BOKER_PROFILE_SUCCESS";
export const GET_BOKER_PROFILE_FAILED = "brokerProfile/GET_BOKER_PROFILE_FAILED";

export const EDIT_BROKER_PASSWORD_STARTED = "brokerProfile/EDIT_BROKER_PASSWORD_STARTED";
export const EDIT_BROKER_PASSWORD_SUCCESS = "brokerProfile/EDIT_BROKER_PASSWORD_SUCCESS";
export const EDIT_BROKER_PASSWORD_FAILED = "brokerProfile/EDIT_BROKER_PASSWORD_FAILED";

export const EDIT_BROKER_PROFILE_STARTED = "brokerProfile/EDIT_BROKER_PROFILE_STARTED";
export const EDIT_BROKER_PROFILE_SUCCESS = "brokerProfile/EDIT_BROKER_PROFILE_SUCCESS";
export const EDIT_BROKER_PROFILE_FAILED = "brokerProfile/EDIT_BROKER_PROFILE_FAILED";

export const BROKER_CHANGE_SEND_EMAIL_STARTED = "brokerProfile/BROKER_CHANGE_SEND_EMAIL_STARTED";
export const BROKER_CHANGE_SEND_EMAIL_SUCCESS = "brokerProfile/BROKER_CHANGE_SEND_EMAIL_SUCCESS";
export const BROKER_CHANGE_SEND_EMAIL_FAILED = "brokerProfile/BROKER_CHANGE_SEND_EMAIL_FAILED";

export const CHANGE_BROKER_IMAGE = "brokerProfile/CHANGE_BROKER_IMAGE";

const initialState = {
  profile: {
    data: {},
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  editPass: {
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  chageSendEmail: {
    error: {},
    failed: false,
    success: false
  },
  editProfile: {
    error: {},
    success: false
  },
  editImage: {
    //data: "",
    success: false
  }
};

export const brokerProfileReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_BOKER_PROFILE_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "profile.error", {});
      newState = update.set(newState, "editImage.success", false);
      newState = update.set(newState, "editPass.success", false)
      break;
    }
    case GET_BOKER_PROFILE_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "profile.success", true);
      newState = update.set(newState, "profile.data", action.payload);
      newState = update.set(newState, "profile.error", {});

      break;
    }
    case GET_BOKER_PROFILE_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", true);
      newState = update.set(newState, "profile.success", false);
      newState = update.set(newState, "profile.error", action.error);

      break;
    }
    case EDIT_BROKER_PASSWORD_STARTED: {
      newState = update.set(state, "editPass.loading", true);
      newState = update.set(newState, "editPass.failed", false);
      newState = update.set(newState, "editPass.success", false);
      newState = update.set(newState, "editPass.error", {});

      break;
    }
    case EDIT_BROKER_PASSWORD_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "editPass.loading", false);
      newState = update.set(newState, "editPass.failed", false);
      newState = update.set(newState, "editPass.success", true);
      // newState = update.set(newState, "editPass.data", action.payload);
      newState = update.set(newState, "editPass.error", {});

      break;
    }
    case EDIT_BROKER_PASSWORD_FAILED: {
      newState = update.set(state, "editPass.loading", false);
      newState = update.set(newState, "editPass.failed", true);
      newState = update.set(newState, "editPass.success", false);
      newState = update.set(newState, "editPass.error", action.error);

      break;
    }
    case EDIT_BROKER_PROFILE_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "editProfile.error", {});

      break;
    }
    case EDIT_BROKER_PROFILE_SUCCESS: {
      //////console.log("Payload Data22: ", action.payload);
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", false);
      newState = update.set(newState, "editProfile.success", true);
      // newState = update.set(newState, "editPass.data", action.payload);
      newState = update.set(newState, "editProfile.error", {});

      break;
    }
    case EDIT_BROKER_PROFILE_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "profile.failed", true);
      newState = update.set(newState, "editProfile.success", false);
      newState = update.set(newState, "editProfile.error", action.error);

      break;
    }

    case BROKER_CHANGE_SEND_EMAIL_STARTED: {
      newState = update.set(state, "profile.loading", true);
      newState = update.set(newState, "chageSendEmail.failed", false);
      newState = update.set(newState, "chageSendEmail.success", false);
      newState = update.set(newState, "chageSendEmail.error", {});

      break;
    }
    case BROKER_CHANGE_SEND_EMAIL_SUCCESS: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "chageSendEmail.failed", false);
      newState = update.set(newState, "chageSendEmail.success", true);
      newState = update.set(newState, "chageSendEmail.error", {});

      break;
    }
    case BROKER_CHANGE_SEND_EMAIL_FAILED: {
      newState = update.set(state, "profile.loading", false);
      newState = update.set(newState, "chageSendEmail.failed", true);
      newState = update.set(newState, "chageSendEmail.success", false);
      newState = update.set(newState, "chageSendEmail.error", action.error);

      break;
    }

    case CHANGE_BROKER_IMAGE: {
     // newState = update.set(state, "editImage.data", action.payload);
      newState = update.set(state, "editImage.success", true);
      break;
    }
    default: {
      return newState;
    }
  }
  return newState;
};

const fetchProfile = dispatch => {
  dispatch(ProfileActions.started());
  ProfileService.getBrokerProfile()
    .then(
      response => {
        dispatch(ProfileActions.success(response));
      },
      error => {
        throw error;
      }
    )
    .catch(error => {
      dispatch(ProfileActions.failed(error.response));
      redirectStatusCode(error.response.status);
    });
};

export const getProfile = () => {
  return dispatch => {
    fetchProfile(dispatch);
  };
};

export const editBrokerPassword = (oldPassword, newPassword) => {
  return dispatch => {
    dispatch(EditBrokerPasswordActions.started());
    ProfileService.editBrokerPassword(oldPassword, newPassword)
      .then(() => {
        dispatch(EditBrokerPasswordActions.success());
      })
      .catch(error => {
        dispatch(EditBrokerPasswordActions.failed(error.response));
        //redirectStatusCode(error.response.status);
      });
  };
};
export const changeSendEmailStatus = (sendEmailDisabled) => {
  return dispatch => {
    dispatch(ChangeSendEmailActions.started());
    ProfileService.changeSendEmailStatus(sendEmailDisabled)
      .then(() => {
        dispatch(getProfile());
        dispatch(ChangeSendEmailActions.success());
      })
      .catch(error => {
        dispatch(ChangeSendEmailActions.failed(error.response));
        //redirectStatusCode(error.response.status);
      });
  };
};

export const editBrokerProfile = (region, email, phoneNumber, companyName, customsControlZones, legalAddress) => {
  return dispatch => {
    dispatch(EditBrokerProfileActions.started());
    ProfileService.editBrokerProfile(region, email, phoneNumber, companyName, customsControlZones, legalAddress)
      .then(() => {
        dispatch(EditBrokerProfileActions.success());
        //fetchProfile(dispatch);
      })
      .catch(error => {
        dispatch(EditBrokerProfileActions.failed(error.response));
        //redirectStatusCode(error.response.status);
      });
  };
};

export const editBrokerImage = (data)=>{
  return dispatch =>{
    ProfileService.changeImage(data)
    .then(response=>{
      dispatch(EditBrokerImageActions.success(response));
      fetchProfile(dispatch);
    })
  }
}

export const EditBrokerPasswordActions = {
  started: () => {
    return {
      type: EDIT_BROKER_PASSWORD_STARTED
    };
  },
  success: () => {
    return {
      type: EDIT_BROKER_PASSWORD_SUCCESS
    };
  },
  failed: response => {
    return {
      type: EDIT_BROKER_PASSWORD_FAILED,
      error: response.data
    };
  }
};

export const EditBrokerProfileActions = {
  started: () => {
    return {
      type: EDIT_BROKER_PROFILE_STARTED
    };
  },
  success: () => {
    return {
      type: EDIT_BROKER_PROFILE_SUCCESS
    };
  },
  failed: response => {
    //////console.log("EDIT_BROKER_PROFILE", response)

    return {
      type: EDIT_BROKER_PROFILE_FAILED,
      error: response.data
    };
  }
};

export const EditBrokerImageActions ={
  success: response => {
    //////console.log("Payload Data: ", response.data);
    return {
      type: CHANGE_BROKER_IMAGE,
      payload: response.data
    };
  },
}

export const ProfileActions = {
  started: () => {
    return {
      type: GET_BOKER_PROFILE_STARTED
    };
  },
  success: response => {
    //////console.log("Payload Data: ", response.data);
    return {
      type: GET_BOKER_PROFILE_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_BOKER_PROFILE_FAILED,
      error: response.data
    };
  }
};

export const ChangeSendEmailActions = {
  started: () => {
    return {
      type: BROKER_CHANGE_SEND_EMAIL_STARTED
    };
  },
  success: response => {
    return {
      type: BROKER_CHANGE_SEND_EMAIL_SUCCESS
    };
  },

  failed: response => {
    return {
      type: BROKER_CHANGE_SEND_EMAIL_SUCCESS,
      error: response.data
    };
  }
};
