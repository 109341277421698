import update from "../../../../helpers/update";
import AdvertListService from "./AdvertListService";

export const GET_ADVERTS_STARTED = "advertsList/GET_ADVERTS_STARTED";
export const GET_ADVERTS_SUCCESS = "advertsList/GET_ADVERTS_SUCCESS";
export const GET_ADVERTS_FAILED = "advertsList/GET_ADVERTS_FAILED";

export const GET_CONTRACTS_STARTED = "advertsList/GET_CONTRACTS_STARTED";
export const GET_CONTRACTS_SUCCESS = "advertsList/GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_FAILED = "advertsList/GET_CONTRACTS_FAILED";


export const GET_CONFIRMED_CONTRACTS_STARTED = "advertsList/GET_CONFIRMED_CONTRACTS_STARTED";
export const GET_CONFIRMED_CONTRACTS_SUCCESS = "advertsList/GET_CONFIRMED_CONTRACTS_SUCCESS";
export const GET_CONFIRMED_CONTRACTS_FAILED = "advertsList/GET_CONFIRMED_CONTRACTS_FAILED";


// export const GET_REJECT_CONTRACTS_STARTED = "advertsList/GET_REJECT_CONTRACTS_STARTED";
// export const GET_REJECT_CONTRACTS_SUCCESS = "advertsList/GET_REJECT_CONTRACTS_SUCCESS";
// export const GET_REJECT_CONTRACTS_FAILED = "advertsList/GET_REJECT_CONTRACTS_FAILED";


const initialState = {
  adverts: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  contracts: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  confirmedContracts: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  rejectContracts: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  }
};

export const listAdvertsReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_ADVERTS_STARTED: {
      newState = update.set(state, "adverts.loading", true);
      newState = update.set(newState, "adverts.failed", false);
      newState = update.set(newState, "adverts.success", false);
      newState = update.set(newState, "adverts.error", {});

      break;
    }
    case GET_ADVERTS_SUCCESS: {
      newState = update.set(state, "adverts.loading", false);
      newState = update.set(newState, "adverts.failed", false);
      newState = update.set(newState, "adverts.success", true);
      newState = update.set(newState, "adverts.error", {});
      newState = update.set(newState, "adverts.data", action.payload);

      break;
    }
    case GET_ADVERTS_FAILED: {
      newState = update.set(state, "adverts.loading", false);
      newState = update.set(newState, "adverts.failed", true);
      newState = update.set(newState, "adverts.success", false);
      newState = update.set(newState, "adverts.error", action.error);

      break;
    }

    case GET_CONTRACTS_STARTED: {
      newState = update.set(state, "contracts.loading", true);
      newState = update.set(newState, "contracts.failed", false);
      newState = update.set(newState, "contracts.success", false);
      newState = update.set(newState, "contracts.error", {});

      break;
    }
    case GET_CONTRACTS_SUCCESS: {
      newState = update.set(state, "contracts.loading", false);
      newState = update.set(newState, "contracts.failed", false);
      newState = update.set(newState, "contracts.success", true);
      newState = update.set(newState, "contracts.error", {});
      newState = update.set(newState, "contracts.data", action.payload);

      break;
    }
    case GET_CONTRACTS_FAILED: {
      newState = update.set(state, "contracts.loading", false);
      newState = update.set(newState, "contracts.failed", true);
      newState = update.set(newState, "contracts.success", false);
      newState = update.set(newState, "contracts.error", action.error);

      break;
    }

    // case GET_REJECT_CONTRACTS_STARTED: {
    //   newState = update.set(state, "rejectContracts.loading", true);
    //   newState = update.set(newState, "rejectContracts.failed", false);
    //   newState = update.set(newState, "rejectContracts.success", false);
    //   newState = update.set(newState, "rejectContracts.error", {});

    //   break;
    // }
    // case GET_REJECT_CONTRACTS_SUCCESS: {
    //   newState = update.set(state, "rejectContracts.loading", false);
    //   newState = update.set(newState, "rejectContracts.failed", false);
    //   newState = update.set(newState, "rejectContracts.success", true);
    //   newState = update.set(newState, "rejectContracts.error", {});
    //   newState = update.set(newState, "rejectContracts.data", action.payload);

    //   break;
    // }
    // case GET_REJECT_CONTRACTS_FAILED: {
    //   newState = update.set(state, "rejectContracts.loading", false);
    //   newState = update.set(newState, "rejectContracts.failed", true);
    //   newState = update.set(newState, "rejectContracts.success", false);
    //   newState = update.set(newState, "rejectContracts.error", action.error);

    //   break;
    // }
    case GET_CONFIRMED_CONTRACTS_STARTED: {
      newState = update.set(state, "confirmedContracts.loading", true);
      newState = update.set(newState, "confirmedContracts.failed", false);
      newState = update.set(newState, "confirmedContracts.success", false);
      newState = update.set(newState, "confirmedContracts.error", {});

      break;
    }

    case GET_CONFIRMED_CONTRACTS_SUCCESS: {
      newState = update.set(state, "confirmedContracts.loading", false);
      newState = update.set(newState, "confirmedContracts.failed", false);
      newState = update.set(newState, "confirmedContracts.success", true);
      newState = update.set(newState, "confirmedContracts.error", {});
      newState = update.set(newState, "confirmedContracts.data", action.payload);

      break;
    }
    case GET_CONFIRMED_CONTRACTS_FAILED: {
      newState = update.set(state, "confirmedContracts.loading", false);
      newState = update.set(newState, "confirmedContracts.failed", true);
      newState = update.set(newState, "confirmedContracts.success", false);
      newState = update.set(newState, "confirmedContracts.error", action.error);

      break;
    }

    default: {
      return newState;
    }
  }
  return newState;
};

export const getAdverts = () => {
  return dispatch => {
    dispatch(GetAdvertsActions.started());
    AdvertListService.getAdverts()
      .then(response => {
        dispatch(GetAdvertsActions.success(response));
      })
      .catch(error => {
        dispatch(GetAdvertsActions.failed(error));
      });
  };
};

// export const getRejectContracts = () => {
//   return dispatch => {
//     dispatch(GetRejectContractsActions.started());
//     AdvertListService.getRejectContracts()
//       .then(response => {
//         dispatch(GetRejectContractsActions.success(response));
//       })
//       .catch(error => {
//         dispatch(GetRejectContractsActions.failed(error));
//       });
//   };
// };

export const getContracts = () => {
  return dispatch => {
    dispatch(GetContractsActions.started());
    AdvertListService.getContracts()
      .then(response => {
        dispatch(GetContractsActions.success(response));
      })
      .catch(error => {
        dispatch(GetContractsActions.failed(error));
      });
  };
};

export const getConfirmedContracts = () => {
  return dispatch => {
    dispatch(GetConfirmedContractsActions.started());
    AdvertListService.getConfirmedContracts()
      .then(response => {
        dispatch(GetConfirmedContractsActions.success(response));
      })
      .catch(error => {
        dispatch(GetConfirmedContractsActions.failed(error));
      });
  };
};

export const GetAdvertsActions = {
  started: () => {
    return {
      type: GET_ADVERTS_STARTED
    };
  },
  success: response => {
    return {
      type: GET_ADVERTS_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_ADVERTS_FAILED,
      error: response.data
    };
  }
};

export const GetContractsActions = {
  started: () => {
    return {
      type: GET_CONTRACTS_STARTED
    };
  },
  success: response => {
    return {
      type: GET_CONTRACTS_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_CONTRACTS_FAILED,
      error: response.data
    };
  }
};

export const GetConfirmedContractsActions = {
  started: () => {
    return {
      type: GET_CONFIRMED_CONTRACTS_STARTED
    };
  },
  success: response => {
    return {
      type: GET_CONFIRMED_CONTRACTS_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_CONFIRMED_CONTRACTS_FAILED,
      error: response.data
    };
  }
};

// export const GetRejectContractsActions = {
//   started: () => {
//     return {
//       type: GET_REJECT_CONTRACTS_STARTED
//     };
//   },
//   success: response => {
//     return {
//       type: GET_REJECT_CONTRACTS_SUCCESS,
//       payload: response.data
//     };
//   },

//   failed: response => {
//     return {
//       type: GET_REJECT_CONTRACTS_FAILED,
//       error: response.data
//     };
//   }
// };
