import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import languageUK from "./translations/uk/translate";
import languageRU from "./translations/ru/translate";

i18n.on("languageChanged", function (lng) {
  let finish = false;
  console.log("her");
  if (!window.location.pathname.includes("/" + lng) ) {
    
    if(lng == "uk" && !window.location.pathname.includes("/ru")){
      finish = true;
    }
    if ( window.location.pathname.includes("/ru") && lng == "uk" ) {
      const newUrl = window.location.pathname.replace(
        "/ru",
        ""
      );
      console.log("new",newUrl)
      window.history.replaceState(null, "", newUrl);
      finish = true;
    }
    if (!finish) {
      window.history.replaceState(
        null,
        "",
        "/" + lng + window.location.pathname
      );
    }
  }
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      uk: languageUK,
      ru: languageRU,
    },
    otherLanguages: [ "uk", "ru"],
    react: {
      wait: true,
      useSuspense: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
    fallbackLng: ["uk"],
    whitelist: [ "uk", "ru"],
    detection: {
      order: ["path",'localStorage'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

export default i18n;
