import {HOME_GET_FAILED, HOME_GET_STARTED, HOME_GET_SUCCESS} from "./types";

const initialState = {
      loading: false,
      success: false,
      failed: false,
      error: "",
      numberOfUsers:0
};

export const homeReducer = (state = initialState, action) => {  
    switch (action.type) {
      case HOME_GET_STARTED: {
        return {
            ...state,
            loading: true
        };
      }
      case HOME_GET_SUCCESS: {
        const {numberOfUsers} = action;
        return {
            ...state,
            loading: false,
            success:true,
            failed:false,
            numberOfUsers
        };
      }
      case HOME_GET_FAILED: {
        return {
            ...state,
            loading: false,
            success:false,
            failed:true,
            error: action.error
        };
      }
      
      default: {
        return {
            ...state
        };
      }
    }
  };