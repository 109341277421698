import axios from 'axios';
import {serverUrl} from '../../../config';

export default class ClientSearchService{
    static getSearchClient(filter){
        const url=`${serverUrl}api/admin/client/search`;
        return axios.post(url, filter);
    }

}


