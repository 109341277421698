import update from '../../../helpers/update';
import PropositionInfoService from './PropositionInfoService';
import history from '../../../utils/history';


export const GET_PROPOSITION_INFO_STARTED = "propositionInfo/GET_PROPOSITION_INFO_STARTED";
export const GET_PROPOSITION_INFO_SUCCESS= "propositionInfo/GET_PROPOSITION_INFO_SUCCESS";
export const GET_PROPOSITION_INFO_FAILED= "propositionInfo/GET_PROPOSITION_INFO_FAILED";

export const REMOVE_CONTRACT_STARTED = "contractInfo/REMOVE_CONTRACT_STARTED";
export const REMOVE_CONTRACT_SUCCESS= "contractInfo/REMOVE_CONTRACT_SUCCESS";
export const REMOVE_CONTRACT_FAILED= "contractInfo/REMOVE_CONTRACT_FAILED";


const intialState = {
    list: {
        data: {},
        error: false,
        loading: false
    },
    removeContract: {
        loading: false,
        error: {}
    }
}

export const propositionInfoReducer = (state = intialState, action)=>{
    let newState = state;

    switch(action.type){
        case GET_PROPOSITION_INFO_STARTED:{
            newState = update.set(state, 'list.loading', true);

            break;
        }
        case GET_PROPOSITION_INFO_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.data);
            break;

        }
        case GET_PROPOSITION_INFO_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
        case REMOVE_CONTRACT_STARTED:{
            newState = update.set(state, 'removeContract.loading', true);
            newState = update.set(newState, 'removeContract.error', {});

            break;
        }
        case REMOVE_CONTRACT_SUCCESS: {
            newState = update.set(state, 'removeContract.loading', false);
            break;

        }
        case REMOVE_CONTRACT_FAILED: {
            newState = update.set(state, 'removeContract.loading', false);
            newState = update.set(newState, 'removeContract.error', action.error);
            break;
        }
    }
    return newState;
}

export const getPropositionInfoData = (propositionId)=>{
    return (dispatch)=>{
        dispatch(getPropositionInfoActions.started());
        PropositionInfoService.getPropositionInfo(propositionId)
            .then((response)=>{
                dispatch(getPropositionInfoActions.success(response));
            })
            .catch(()=>{
                dispatch(getPropositionInfoActions.failed());
            });
    }
}

export const removeOffer = (offerId)=>{
    return (dispatch)=>{
        dispatch(removeContractActions.started());
        PropositionInfoService.removeContract(offerId)
            .then(()=>{
                dispatch(removeContractActions.success());
                history.push("/broker/profile");
                document.location.reload()

            })
            .catch((error)=>{
                dispatch(removeContractActions.failed(error));
                alert(error.data);
            })
    }
}
export const getPropositionInfoActions = {
    started: () => {
        return {
            type: GET_PROPOSITION_INFO_STARTED
        }
    },

    success: (data) => {
        return {
            type: GET_PROPOSITION_INFO_SUCCESS,
            payload: data
        }
    },

    failed: (error) => {
        return {
            type: GET_PROPOSITION_INFO_FAILED
        }
    }
}
export const removeContractActions = {
    started: () => {
        return {
            type: REMOVE_CONTRACT_STARTED
        }
    },

    success: () => {
        return {
            type: REMOVE_CONTRACT_SUCCESS,
        }
    },

    failed: (response) => {
        return {
            type: REMOVE_CONTRACT_FAILED,
            error: response.data
        }
    }
}