import ClientSearchService from "./ClientSearchService";
import update from '../../../helpers/update';


export const GET_SEARCH_CLIENT_SUCCESS = "admin/GET_SEARCH_CLIENT_SUCCESS";
export const GET_SEARCH_CLIENT_STARTED = "admin/GET_SEARCH_CLIENT_STARTED";
export const GET_SEARCH_CLIENT_FAILED = "admin/GET_SEARCH_CLIENT_FAILED";

const initialState = {
    list: {
        data: [],
        error: false,
        loading: false
    },
    currentPageClient: 1,
    totalClientsCount: 0,
    typeOfSort: 'default',
    sortByAscending: 'true'
}

export const getSearchClientData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        ClientSearchService.getSearchClient(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const clientSearchReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case GET_SEARCH_CLIENT_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case GET_SEARCH_CLIENT_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.clients);
            newState = update.set(newState, 'currentPageClient', action.payload.currentPageClient);
            newState = update.set(newState, 'totalClientsCount', action.payload.totalClientsCount);
            newState = update.set(newState, 'typeOfSort', action.payload.typeOfSort);
            newState = update.set(newState, 'sortByAscending', action.payload.sortByAscending);
            break;
        }
        case GET_SEARCH_CLIENT_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: GET_SEARCH_CLIENT_STARTED
        }
    },
    success: (data) => {
        return {
            type: GET_SEARCH_CLIENT_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: GET_SEARCH_CLIENT_FAILED
        }
    }
}



