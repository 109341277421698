import update from "../../../../helpers/update";
import AdvertDetailsService from "./AdvertDetailsService";
import history from '../../../../utils/history';

//import { dispatch } from "rxjs/internal/observable/range";

export const GET_ADVERT_OFFERS_STARTED = "advertDetails/GET_ADVERT_OFFERS_STARTED";
export const GET_ADVERT_OFFERS_SUCCESS = "advertDetails/GET_ADVERT_OFFERS_SUCCESS";
export const GET_ADVERT_OFFERS_FAILED = "advertDetails/GET_ADVERT_OFFERS_FAILED";

export const GET_APPROVED_OFFER_INFO_STARTED = "advertDetails/GET_APPROVED_OFFER_INFO_INFO_STARTED";
export const GET_APPROVED_OFFER_INFO_SUCCESS = "advertDetails/GET_APPROVED_OFFER_INFO_SUCCESS";
export const GET_APPROVED_OFFER_INFO_FAILED = "advertDetails/GET_APPROVED_OFFER_INFO_FAILED";

export const GET_CONTRACT_INFO_STARTED = "advertDetails/GET_GET_CONTRACT_INFO_INFO_STARTED";
export const GET_CONTRACT_INFO_SUCCESS = "advertDetails/GET_GET_CONTRACT_INFO_SUCCESS";
export const GET_CONTRACT_INFO_FAILED = "advertDetails/GET_GET_CONTRACT_INFO_FAILED";

export const GET_ADVERT_IMAGES_STARTED = "advertDetails/GET_ADVERT_IMAGES_STARTED";
export const GET_ADVERT_IMAGES_SUCCESS = "advertDetails/GET_ADVERT_IMAGES_SUCCESS";
export const GET_ADVERT_IMAGES_FAILED = "advertDetails/GET_ADVERT_IMAGES_FAILED";

export const GET_ADVERT_DESCRIPTION_STARTED = "advertDetails/GET_ADVERT_DESCRIPTION_STARTED";
export const GET_ADVERT_DESCRIPTION_SUCCESS = "advertDetails/GET_ADVERT_DESCRIPTION_SUCCESS";
//export const GET_ADVERT_DESCRIPTION_SUCCESS = "advertDetails/GET_ADVERT_DESCRIPTION_SUCCESS";

export const CHANGE_ADVERT_IMAGE = "advertDetails/CHANGE_ADVERT_IMAGE";


export const GET_DOCUMENT_STARTED = "advertDetails/GET_DOCUMENT_STARTED";
export const GET_DOCUMENT_SUCCESS = "advertDetails/GET_DOCUMENT_SUCCESS";

export const CONFIRM_DOCUMENT_STARTED = "advertDetails/CONFIRM_DOCUMENT_STARTED";

export const CONFIRM_DOCUMENT_SUCCESS = "advertDetails/CONFIRM_DOCUMENT_SUCCESS";






const initialState = {
  offers: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  },

  approvedOffer: {
    data: {},
    error: {},
    loading: false,
    failed: false,
    success: false
  },

  images: {
    data: [],
    error: {},
    loading: false,
    failed: false,
    success: false
  },

  description: {
    data: {},
    loading: false,
    success: false
  },
  contract: {
    data: {},
    error: {},
    loading: false,
    failed: false,
    success: false
  },
  document: {
    data: {},
    loading: false
  },
  confirmDocument: {
    loading: false, 
    success: false
  }

};

export const advertDetailsReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {


    case CONFIRM_DOCUMENT_STARTED:{
      newState = update.set(state, "confirmDocument.loading", true);
      newState = update.set(state, "confirmDocument.success", false);
      break;
    }
    case CONFIRM_DOCUMENT_SUCCESS:{
      newState = update.set(state, "confirmDocument.loading", false);
      newState = update.set(state, "confirmDocument.success", true);
      break;
    }




    case GET_ADVERT_OFFERS_STARTED: {
      newState = update.set(state, "offers.loading", true);
      newState = update.set(newState, "offers.failed", false);
      newState = update.set(newState, "offers.success", false);
      newState = update.set(newState, "offers.error", {});

      break;
    }
    case GET_ADVERT_OFFERS_SUCCESS: {
      newState = update.set(state, "offers.loading", false);
      newState = update.set(newState, "offers.failed", false);
      newState = update.set(newState, "offers.success", true);
      newState = update.set(newState, "offers.error", {});
      newState = update.set(newState, "offers.data", action.payload);

      break;
    }
    case GET_ADVERT_OFFERS_FAILED: {
      newState = update.set(state, "offers.loading", false);
      newState = update.set(newState, "offers.failed", true);
      newState = update.set(newState, "offers.success", false);
      newState = update.set(newState, "offers.error", action.error);

      break;
    }

    case GET_APPROVED_OFFER_INFO_STARTED: {
      newState = update.set(state, "approvedOffer.loading", true);
      newState = update.set(newState, "approvedOffer.failed", false);
      newState = update.set(newState, "approvedOffer.success", false);
      newState = update.set(newState, "approvedOffer.error", {});

      break;
    }
    case GET_APPROVED_OFFER_INFO_SUCCESS: {
      newState = update.set(state, "approvedOffer.loading", false);
      newState = update.set(newState, "approvedOffer.failed", false);
      newState = update.set(newState, "approvedOffer.success", true);
      newState = update.set(newState, "approvedOffer.error", {});
      newState = update.set(newState, "approvedOffer.data", action.payload);

      break;
    }
    case GET_APPROVED_OFFER_INFO_FAILED: {
      newState = update.set(state, "approvedOffer.loading", false);
      newState = update.set(newState, "approvedOffer.failed", true);
      newState = update.set(newState, "approvedOffer.success", false);
      newState = update.set(newState, "approvedOffer.error", action.error);

      break;
    }
    case GET_CONTRACT_INFO_STARTED: {
      newState = update.set(state, "contract.loading", true);
      newState = update.set(newState, "contract.failed", false);
      newState = update.set(newState, "contract.success", false);
      newState = update.set(newState, "contract.error", {});

      break;
    }
    case GET_CONTRACT_INFO_SUCCESS: {
      newState = update.set(state, "contract.loading", false);
      newState = update.set(newState, "contract.failed", false);
      newState = update.set(newState, "contract.success", true);
      newState = update.set(newState, "contract.error", {});
      newState = update.set(newState, "contract.data", action.payload);

      break;
    }
    case GET_CONTRACT_INFO_FAILED: {
      newState = update.set(state, "contract.loading", false);
      newState = update.set(newState, "contract.failed", true);
      newState = update.set(newState, "contract.success", false);
      newState = update.set(newState, "contract.error", action.error);

      break;
    }

    case GET_DOCUMENT_STARTED: {
      newState = update.set(state, "document.loading", true);
      break;
    }

    case GET_DOCUMENT_SUCCESS: {
      newState = update.set(state, "document.loading", false);
      newState = update.set(newState, "document.data", action.document);
      break;
      //////console.log("action.payload: ", action.payload)
    }


    case GET_ADVERT_IMAGES_STARTED: {
      newState = update.set(state, "images.loading", true);
      newState = update.set(newState, "images.failed", false);
      newState = update.set(newState, "images.success", false);
      newState = update.set(newState, "images.error", {});

      break;
    }
    case GET_ADVERT_IMAGES_SUCCESS: {
      newState = update.set(state, "images.loading", false);
      newState = update.set(newState, "images.failed", false);
      newState = update.set(newState, "images.success", true);
      newState = update.set(newState, "images.error", {});
      newState = update.set(newState, "images.data", action.payload);

      break;
    }
    case GET_ADVERT_IMAGES_FAILED: {
      newState = update.set(state, "images.loading", false);
      newState = update.set(newState, "images.failed", true);
      newState = update.set(newState, "images.success", false);
      newState = update.set(newState, "images.error", action.error);

      break;
    }
    case GET_ADVERT_DESCRIPTION_STARTED: {
      newState = update.set(newState, "description.loading", true);
      newState = update.set(newState, "description.success", false);

      break;
    }
    case GET_ADVERT_DESCRIPTION_SUCCESS: {
      newState = update.set(newState, "description.loading", false);
      newState = update.set(newState, "description.success", false);
      newState = update.set(newState, "description.data", action.description);
      break;
    }

    case CHANGE_ADVERT_IMAGE: {
      newState = {
        ...state,
        images: {
          data: state.images.data.map(item => {
            if(item.id === action.image.id)
              return action.image;
            return item;
          })
        }
      };
      break;
    }

    default: {
      return newState;
    }
  }
  return newState;
};


export const confirmDocument = (contractId)=>{
  return dispatch => {
    dispatch(ConfirmDocumentActions.started())
    AdvertDetailsService.confirmDocument(contractId)
      .then(()=>{
        dispatch(ConfirmDocumentActions.success())
        history.push("/client/confirmed-contracts");
        document.location.reload()

      })
  }
}

export const getDocument = (id, isContract) =>{
  return dispatch =>{
    dispatch( GetDocumentActions.started());
  AdvertDetailsService.getDocument(id, isContract)
      .then(response=>{
        dispatch(GetDocumentActions.success(response));
      })
  }
}
export const getAdvertOffers = advertId => {
  return dispatch => {
    dispatch(GetAdvertOffersActions.started());
    AdvertDetailsService.getOffers(advertId)
      .then(response => {
        //////console.log("AdvertOffersActions", response)
        dispatch(GetAdvertOffersActions.success(response));
      })
      .catch(error => {
        dispatch(GetAdvertOffersActions.failed(error));
      });
  };
};
export const getApprovedOffer = advertId => {
  return dispatch => {
    dispatch(GetApprovedOfferInfoActions.started());
    AdvertDetailsService.getApprovedOfferInfo(advertId)
      .then(response => {
        //////console.log("AdvertOffersActions", response)
        dispatch(GetApprovedOfferInfoActions.success(response));
      })
      .catch(error => {
        dispatch(GetApprovedOfferInfoActions.failed(error));
      });
  };
};

export const getContract = advertId => {
  return dispatch => {
    dispatch(GetContractInfoActions.started());
    AdvertDetailsService.getContractInfo(advertId)
      .then(response => {
        //////console.log("AdvertOffersActions", response)
        dispatch(GetContractInfoActions.success(response));
      })
      .catch(error => {
        dispatch(GetContractInfoActions.failed(error));
      });
  };
};



export const getAdvertImages = advertId => {
  return dispatch => {
    dispatch(GetAdvertImagesActions.started());
    AdvertDetailsService.getAdvertImages(advertId)
      .then(response => {
        //////console.log("AdvertOffersActions", response)
        dispatch(GetAdvertImagesActions.success(response));

      })
      .catch(error => {
        dispatch(GetAdvertImagesActions.failed(error));
      });
  };
};

export const changeAdvertImage=(model)=>{
  return dispatch => {
    AdvertDetailsService.changeImage(model)
      .then(response => {
        dispatch(ChangeAdvertImageActions.success(response))
      })
  }
}

export const getAdvertDescription=(advertId)=>{
  return dispatch => {
    dispatch(AdvertDescriptionActions.started());
    AdvertDetailsService.getAdvertDescription(advertId)
      .then(response=>{
        dispatch(AdvertDescriptionActions.success(response));
      })
  }
}

export const GetAdvertOffersActions = {
  started: () => {
    return {
      type: GET_ADVERT_OFFERS_STARTED
    };
  },
  success: response => {
    return {
      type: GET_ADVERT_OFFERS_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_ADVERT_OFFERS_FAILED,
      error: response.data
    };
  }
};

export const GetApprovedOfferInfoActions = {
  started: () => {
    return {
      type: GET_APPROVED_OFFER_INFO_STARTED
    };
  },
  success: response => {
    //////console.log("response",response)
    return {
      type: GET_APPROVED_OFFER_INFO_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_APPROVED_OFFER_INFO_FAILED,
      error: response.data
    };
  }
};


export const GetContractInfoActions = {
  started: () => {
    return {
      type: GET_CONTRACT_INFO_STARTED
    };
  },
  success: response => {
    //////console.log("response",response)
    return {
      type: GET_CONTRACT_INFO_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_CONTRACT_INFO_FAILED,
      error: response.data
    };
  }
};



export const ConfirmDocumentActions = {
  started: () => {
    return {
      type: CONFIRM_DOCUMENT_STARTED
    }
  },
  success: () =>{
    return {
      type: CONFIRM_DOCUMENT_SUCCESS
    }
  }
}

export const ChangeAdvertImageActions ={
  success: response => {
    return {
    type: CHANGE_ADVERT_IMAGE,
    image: response.data
    }
  }
}


export const GetDocumentActions = {
  started: ()=>{
    return {
      type: GET_DOCUMENT_STARTED
    }
  },
  success: response => {
    //////console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSs:____",response )
    return {
      type: GET_DOCUMENT_SUCCESS,
      document: response.data
    }
  }
}

export const AdvertDescriptionActions = {
  started: () => {
    return {
      type: GET_ADVERT_DESCRIPTION_STARTED
    }
  },
  success: response => {
    return {
    type: GET_ADVERT_DESCRIPTION_SUCCESS,
    description: response.data
    }
  }
}

export const GetAdvertImagesActions = {
  started: () => {
    return {
      type: GET_ADVERT_IMAGES_STARTED
    };
  },
  success: response => {
    //////console.log("response",response)
    return {
      type: GET_ADVERT_IMAGES_SUCCESS,
      payload: response.data
    };
  },

  failed: response => {
    return {
      type: GET_ADVERT_IMAGES_FAILED,
      error: response.data
    };
  }
};
