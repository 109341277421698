import update from "../../../helpers/update";
import PropositionService from "./PropositionService";

export const GET_SENDED_PROPOSTIONS_STARTED = "propostionsAndContracts/GET_SENDED_PROPOSTIONS_STARTED";
export const GET_SENDED_PROPOSTIONS_SUCCESS = "propostionsAndContracts/GET_SENDED_PROPOSTIONS_SUCCESS";
export const GET_SENDED_PROPOSTIONS_FAILED = "propostionsAndContracts/GET_SENDED_PROPOSTIONS_FAILED";

// export const GET_CONFIRMED_PROPOSTIONS_STARTED = "propostionsAndContracts/GET_CONFIRMED_PROPOSTIONS_STARTED";
// export const GET_CONFIRMED_PROPOSTIONS_SUCCESS = "propostionsAndContracts/GET_CONFIRMED_PROPOSTIONS_SUCCESS";
// export const GET_CONFIRMED_PROPOSTIONS_FAILED = "propostionsAndContracts/GET_CONFIRMED_PROPOSTIONS_FAILED";

export const GET_ENDED_PROPOSTIONS_STARTED = "propostionsAndContracts/GET_ENDED_PROPOSTIONS_STARTED";
export const GET_ENDED_PROPOSTIONS_SUCCESS = "propostionsAndContracts/GET_ENDED_PROPOSTIONS_SUCCESS";
export const GET_ENDED_PROPOSTIONS_FAILED = "propostionsAndContracts/GET_ENDED_PROPOSTIONS_FAILED";

export const GET_CONTRACTS_STARTED = "propostionsAndContracts/GET_CONTRACTS_STARTED";
export const GET_CONTRACTS_SUCCESS = "propostionsAndContracts/GET_CONTRACTS_SUCCESS";
export const GET_CONTRACTS_FAILED = "propostionsAndContracts/GET_CONTRACTS_FAILED";


const intialState = {
  list: {
    data: [],
    error: false,
    loading: false
  },
  contractList: {
    data: [],
    error: false,
    loading: false
  }
};

export const propostionsAndContractsReducer = (state = intialState, action) => {
  let newState = state;
  switch (action.type) {
    case GET_SENDED_PROPOSTIONS_STARTED: {
      newState = update.set(state, "list.loading", true);
      break;
    }
    case GET_SENDED_PROPOSTIONS_SUCCESS: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.data", action.payload.data);
      break;
    }
    case GET_SENDED_PROPOSTIONS_FAILED: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.error", true);
      break;
    }

    // case GET_CONFIRMED_PROPOSTIONS_STARTED: {
    //   newState = update.set(state, "list.loading", true);
    //   break;
    // }
    // case GET_CONFIRMED_PROPOSTIONS_SUCCESS: {
    //   newState = update.set(state, "list.loading", false);
    //   newState = update.set(newState, "list.data", action.payload.data);
    //   break;
    // }
    // case GET_CONFIRMED_PROPOSTIONS_FAILED: {
    //   newState = update.set(state, "list.loading", false);
    //   newState = update.set(newState, "list.error", true);
    //   break;
    // }

    case GET_ENDED_PROPOSTIONS_STARTED: {
      newState = update.set(state, "list.loading", true);
      break;
    }
    case GET_ENDED_PROPOSTIONS_SUCCESS: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.data", action.payload.data);
      break;
    }
    case GET_ENDED_PROPOSTIONS_FAILED: {
      newState = update.set(state, "list.loading", false);
      newState = update.set(newState, "list.error", true);
      break;
    }

    case GET_CONTRACTS_STARTED: {
      newState = update.set(state, "contractList.loading", true);
      break;
    }
    case GET_CONTRACTS_SUCCESS: {
      newState = update.set(state, "contractList.loading", false);
      newState = update.set(newState, "contractList.data", action.payload.data);
      break;
    }
    case GET_CONTRACTS_FAILED: {
      newState = update.set(state, "contractList.loading", false);
      newState = update.set(newState, "contractList.error", true);
      break;
    }

    default: {
      return newState;
    }
  }
  return newState;
};

export const getSendedPropositionsData = ()=>{

  return (dispatch)=>{
    dispatch(SendedPropositionsActions.started());
    PropositionService.getPropostionsAndContracts(1)
      .then((response)=>{
        dispatch(SendedPropositionsActions.success(response));
      })
      .catch(()=>{
        dispatch(SendedPropositionsActions.failed());
      })
  }
}

// export const getConfirmedPropositionsData = ()=>{
//   return (dispatch)=>{
//     dispatch(ConfirmedPropositionsActions.started());
//     PropositionService.getPropostionsAndContracts(2)
//       .then((response)=>{
//         dispatch(ConfirmedPropositionsActions.success(response));
//       })
//       .catch(()=>{
//         dispatch(ConfirmedPropositionsActions.failed());
//       })
//   }
// }
export const getEndedPropositionsData = ()=>{
  return (dispatch)=>{
    dispatch(EndedPropositionsActions.started());
    PropositionService.getPropostionsAndContracts(3)
      .then((response)=>{
        dispatch(EndedPropositionsActions.success(response));
      })
      .catch(()=>{
        dispatch(EndedPropositionsActions.failed());
      })
  }
}

export const getContractsData = (page)=>{

  return (dispatch)=>{
    dispatch(ContractsActions.started());
    PropositionService.getPropostionsAndContracts(4,page)
      .then((response)=>{
        dispatch(ContractsActions.success(response));
      })
      .catch(()=>{
        dispatch(ContractsActions.failed());
      })
  }
}


export const SendedPropositionsActions = {
  started: () => {
    return {
      type: GET_SENDED_PROPOSTIONS_STARTED
    };
  },

  success: data => {
    return {
      type: GET_SENDED_PROPOSTIONS_SUCCESS,
      payload: data
    };
  },

  failed: error => {
    return {
      type: GET_SENDED_PROPOSTIONS_FAILED
    };
  }
};
// export const ConfirmedPropositionsActions = {
//   started: () => {
//     return {
//       type: GET_CONFIRMED_PROPOSTIONS_STARTED
//     };
//   },

//   success: data => {
//     return {
//       type: GET_CONFIRMED_PROPOSTIONS_SUCCESS,
//       payload: data
//     };
//   },

//   failed: error => {
//     return {
//       type: GET_CONFIRMED_PROPOSTIONS_FAILED
//     };
//   }
// };
export const EndedPropositionsActions = {
  started: () => {
    return {
      type: GET_ENDED_PROPOSTIONS_STARTED
    };
  },

  success: data => {
    return {
      type: GET_ENDED_PROPOSTIONS_SUCCESS,
      payload: data
    };
  },

  failed: error => {
    return {
      type: GET_ENDED_PROPOSTIONS_FAILED
    };
  }
};

export const ContractsActions = {
  started: () => {
    return {
      type: GET_CONTRACTS_STARTED
    };
  },

  success: data => {
    return {
      type: GET_CONTRACTS_SUCCESS,
      payload: data
    };
  },

  failed: error => {
    return {
      type: GET_CONTRACTS_FAILED
    };
  }
};

