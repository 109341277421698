import { SET_CLIENT_PROFILE,
    SET_CLIENT_IMAGE,
    ADD_ADVERT,
    SET_CLIENT_ADVERTS,
    SET_CLIENT_ADVERT,
    SET_CLIENT_ADVERT_IMAGES,
    SET_CLIENT_ADVERT_OFFERS,
    SET_CLIENT_ADVERTS_QUANTITY,
    SET_OFFERS_FOR_ADVERT_QUANTITY,
    SET_CLIENT_REJECT_CONTRACTS,
    SET_APPROVED_OFFER_INFO,
    SET_CLIENT_CONTRACTS,
    UPDATE_CLIENT_IMAGE
 } from '../actions/types';

const initialState = {
    profile: {
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        image: '',
        loading: false,
        error: false
    },
    adverts: [],
    advertsQnt: 0,
    offersForAdvertQnt: 0,
    advert: {
      loading: false,
      images: []
    },
    advertImages: [],
    advertOffers: [],
    approvedOfferInfo: {},
    contracts: [],
    reject_contracts: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_CLIENT_PROFILE:
        return Object.assign({}, state, { profile: action.profile });
      case SET_CLIENT_IMAGE:
        return Object.assign({}, state, { profile: Object.assign(...state.profile, { image: action.image }) });
      case SET_CLIENT_ADVERTS:
        return Object.assign({}, state, { adverts: action.adverts });
      case ADD_ADVERT:
        return Object.assign({}, state, {
          profile: Object.assign(...state.profile, { adverts: [...state.profile.adverts, action.advert] })
        });
      case SET_CLIENT_ADVERTS_QUANTITY:
        return Object.assign({}, state, { advertsQnt: action.quantity });
      case SET_OFFERS_FOR_ADVERT_QUANTITY:
        return Object.assign({}, state, { offersForAdvertQnt: action.quantity });
      case SET_CLIENT_ADVERT:
        return Object.assign({}, state, { advert: action.advert });
      case SET_CLIENT_ADVERT_IMAGES:
        return Object.assign({}, state, { advertImages: action.images });
      case SET_CLIENT_ADVERT_OFFERS:
        return Object.assign({}, state, { advertOffers: action.offers });
      case SET_APPROVED_OFFER_INFO:
        return Object.assign({}, state, { approvedOfferInfo: action.offerInfo });
      case SET_CLIENT_CONTRACTS:
        return Object.assign({}, state, { contracts: action.contracts });

      case SET_CLIENT_REJECT_CONTRACTS:
        return Object.assign({}, state, { reject_contracts: action.reject_contracts });

      case UPDATE_CLIENT_IMAGE: {
          ////console.log('----KABAN------', action.image);
          ////console.log('----KABAN------', {...state.advert.images})
          return {
            ...state,
            advert: {
              images:  state.advert.images.map(item => {
              if (item.id === action.image.id)
                  return action.image;
              return item;
              })
            }
           };

      }

      default:
        return state;
    }
}
