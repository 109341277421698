import BellService from "./BellService";
import update from '../helpers/update';

export const BELL_BROKERS_SUCCESS = "BELL_BROKERS_SUCCESS";
export const BELL_BROKERS_STARTED = "BELL_BROKERS_STARTED";
export const BELL_BROKERS_FAILED = "BELL_BROKERS_FAILED";
export const BELL_CLIENTS_SUCCESS = "BELL_CLIENTS_SUCCESS";
export const BELL_CLIENTS_STARTED = "BELL_CLIENTS_STARTED";
export const BELL_CLIENTS_FAILED = "BELL_CLIENTS_FAILED";
export const COUNT_ADVERTS_SUCCESS = "COUNT_ADVERTS_SUCCESS";
export const COUNT_ADVERTS_STARTED = "COUNT_ADVERTS_STARTED";
export const COUNT_ADVERTS_FAILED = "COUNT_ADVERTS_FAILED";
//getUnconfirmedContracts
export const GET_UNCONFIRMED_CONTRACTS = "GET_UNCONFIRMED_CONTRACTS";
export const GET_CONFIRMED_CONTRACTS = "GET_CONFIRMED_CONTRACTS";



const initialState = {
  list: {
    data: [],
    loading: false,
    error: false
  },
  list1: {
    data: [],
    loading: false,
    error: false
  },
  countAdverts: {
    count: 0,
    loading: false,
    error: false
  },
  unconfirmedContracts: {
    count: 0,
    advertId: 0
  },
  confirmedContracts: 0
}


export const getUnconfirmed = () => {
  return dispatch => {
    BellService.getUnconfirmedContracts()
      .then((response) => {
        dispatch({
          type: GET_UNCONFIRMED_CONTRACTS,
          payload: response.data
        })
      })
  };
}
export const getConfirmed = () => {
  return dispatch => {
    BellService.getConfirmedContracts()
      .then((response) => {
        dispatch({
          type: GET_CONFIRMED_CONTRACTS,
          payload: response.data
        })
      })
  };
}

const getCounterAdvertsData = dispatch => {
  dispatch(getCounterAdvertsActions.started());
  BellService.getAdvertsCount()
    .then(
      response => {
        dispatch(getCounterAdvertsActions.success(response));
      },
      error => {
        throw error;
      }
    )
    .catch(error => {
      dispatch(getCounterAdvertsActions.failed(error.response));
    });
};

const getBrokersBellData = dispatch => {
  dispatch(getBrokersActions.started());
  BellService.getBrokerMessages()
    .then(
      response => {
        dispatch(getBrokersActions.success(response));
      },
      error => {
        throw error;
      }
    )
    .catch(error => {
      dispatch(getBrokersActions.failed(error.response));
    });
};

const getClientsBellData = dispatch => {
  dispatch(getClientsActions.started());
  BellService.getClientMessages()
    .then(
      response => {
        dispatch(getClientsActions.success(response));
      },
      error => {
        throw error;
      }
    )
    .catch(error => {
      dispatch(getClientsActions.failed(error.response));
    });
};

export const getAdvertsCount = () => {
  return dispatch => {
    getCounterAdvertsData(dispatch);
  };
};

export const getDataBroker = () => {
  return dispatch => {
    getBrokersBellData(dispatch);
  };
};

export const getDataClient = () => {
  return dispatch => {
    getClientsBellData(dispatch);
  };
};



export const brokerBellReducer = (state = initialState, action) => {

  let newState = state;
  switch (action.type) {
    case BELL_BROKERS_STARTED: {
      newState = update.set(state, 'list.loading', true);
      break;
    }
    case BELL_BROKERS_SUCCESS: {
      newState = update.set(state, 'list.loading', false);
      newState = update.set(newState, 'list.data', action.payload.messages);
      break;
    }
    case BELL_BROKERS_FAILED: {
      newState = update.set(state, 'list.loading', false);
      newState = update.set(newState, 'list.error', true);
      break;
    }
    case BELL_CLIENTS_STARTED: {
      newState = update.set(state, 'list1.loading', true);
      break;
    }
    case BELL_CLIENTS_SUCCESS: {
      newState = update.set(state, 'list1.loading', false);
      newState = update.set(newState, 'list1.data', action.payload.messages);
      break;
    }
    case BELL_CLIENTS_FAILED: {
      newState = update.set(state, 'list1.loading', false);
      newState = update.set(newState, 'list1.error', true);
      break;
    }
    case COUNT_ADVERTS_STARTED: {
      newState = update.set(state, 'countAdverts.loading', true);
      break;
    }
    case COUNT_ADVERTS_SUCCESS: {
      newState = update.set(state, 'countAdverts.loading', false);
      newState = update.set(newState, 'countAdverts.count', action.payload);
      break;
    }
    case COUNT_ADVERTS_FAILED: {
      newState = update.set(state, 'countAdverts.loading', false);
      newState = update.set(newState, 'countAdverts.error', true);
      break;
    }
    case GET_UNCONFIRMED_CONTRACTS: {
      newState = update.set(state, 'unconfirmedContracts', action.payload);

      break;
    }
    case GET_CONFIRMED_CONTRACTS: {
      newState = update.set(state, 'confirmedContracts', action.payload);

      break;
    }


    default: {
      return newState;
    }
  }
  return newState;
}

export const getBrokersActions = {
  started: () => {
    return {
      type: BELL_BROKERS_STARTED
    }
  },
  success: (data) => {
    return {
      type: BELL_BROKERS_SUCCESS,
      payload: data.data
    }
  },
  failed: (error) => {
    return {
      type: BELL_BROKERS_FAILED
    }
  }
}

export const getClientsActions = {
  started: () => {
    return {
      type: BELL_CLIENTS_STARTED
    }
  },
  success: (data) => {
    return {
      type: BELL_CLIENTS_SUCCESS,
      payload: data.data
    }
  },
  failed: (error) => {
    return {
      type: BELL_CLIENTS_FAILED
    }
  }
}

export const getCounterAdvertsActions = {
  started: () => {
    return {
      type: COUNT_ADVERTS_STARTED
    }
  },
  success: (data) => {
    return {
      type: COUNT_ADVERTS_SUCCESS,
      payload: data.data
    }
  },
  failed: (error) => {
    return {
      type: COUNT_ADVERTS_FAILED
    }
  }
}



