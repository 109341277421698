import update from '../../../helpers/update';
import ContractInfoService from './ContractInfoService';
// import redirectStatusCode from '../../services/redirectStatusCode';
export const GET_CONTRACT_INFO_STARTED = "contractInfo/GET_CONTRACT_INFO_STARTED";
export const GET_CONTRACT_INFO_SUCCESS= "contractInfo/GET_CONTRACT_INFO_SUCCESS";
export const GET_CONTRACT_INFO_FAILED= "contractInfo/GET_CONTRACT_INFO_FAILED";

export const GET_CONFIRMED_CONTRACT_INFO_STARTED = "contractInfo/GET_CONFIRMED_CONTRACT_INFO_STARTED";
export const GET_CONFIRMED_CONTRACT_INFO_SUCCESS= "contractInfo/GET_CONFIRMED_CONTRACT_INFO_SUCCESS";
export const GET_CONFIRMED_CONTRACT_INFO_FAILED= "contractInfo/GET_CONFIRMED_CONTRACT_INFO_FAILED";





const intialState = {
    list: {
        data: {},
        error: false,
        loading: false

    },
    confirmedContract: {
        data: {},
        error: false,
        loading: false
    },
    

}

export const contractInfoReducer = (state = intialState, action)=>{
    let newState = state;

    switch(action.type){
        case GET_CONTRACT_INFO_STARTED:{
            newState = update.set(state, 'list.loading', true);
            newState = update.set(newState, 'list.error', false);

            break;
        }
        case GET_CONTRACT_INFO_SUCCESS: {
            newState = update.set(newState, 'list.error', false);
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.data);
            break;

        }
        case GET_CONTRACT_INFO_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        case GET_CONFIRMED_CONTRACT_INFO_STARTED:{
            newState = update.set(state, 'confirmedContract.loading', true);
            newState = update.set(newState, 'confirmedContract.error', false);

            break;
        }
        case GET_CONFIRMED_CONTRACT_INFO_SUCCESS: {
            newState = update.set(state, 'confirmedContract.loading', false);
            newState = update.set(newState, 'confirmedContract.error', false);
            newState = update.set(newState, 'confirmedContract.data', action.payload.data);
            break;

        }
        case GET_CONFIRMED_CONTRACT_INFO_FAILED: {
            newState = update.set(state, 'confirmedContract.loading', false);
            newState = update.set(newState, 'confirmedContract.error', true);
            break;
        }
        
        default: {
            return newState;
        }
    }
    return newState;
}

export const getContractInfoData = (contractId)=>{
    return (dispatch)=>{
        dispatch(getContractInfoActions.started());
        ContractInfoService.getContractInfo(contractId)
            .then((response)=>{
                dispatch(getContractInfoActions.success(response));
            })
            .catch(()=>{
                dispatch(getContractInfoActions.failed());
            });
    }
}

export const getConfirmedContractInfoData = (contractId)=>{
    return (dispatch)=>{
        dispatch(getConfirmedContractInfoActions.started());
        ContractInfoService.getConfirmedContractInfo(contractId)
            .then((response)=>{
                dispatch(getConfirmedContractInfoActions.success(response));
            })
            .catch(()=>{
                dispatch(getConfirmedContractInfoActions.failed());
            });
    }
}


export const getContractInfoActions = {
    started: () => {
        return {
            type: GET_CONTRACT_INFO_STARTED
        }
    },

    success: (data) => {
        return {
            type: GET_CONTRACT_INFO_SUCCESS,
            payload: data
        }
    },

    failed: (error) => {
        return {
            type: GET_CONTRACT_INFO_FAILED
        }
    }
}



export const getConfirmedContractInfoActions = {
    started: () => {
        return {
            type: GET_CONFIRMED_CONTRACT_INFO_STARTED
        }
    },

    success: (data) => {
        return {
            type: GET_CONFIRMED_CONTRACT_INFO_SUCCESS,
            payload: data
        }
    },

    failed: (error) => {
        return {
            type: GET_CONFIRMED_CONTRACT_INFO_FAILED
        }
    }
}
