import OfferDetailsService from "./OfferDetailsService";
import update from '../../../helpers/update';
import history from "../../../utils/history";


export const OFFER_DETAILS_SUCCESS = "admin/OFFER_DETAILS_SUCCESS";
export const OFFER_DETAILS_STARTED = "admin/OFFER_DETAILS_STARTED";
export const OFFER_DETAILS_FAILED = "admin/OFFER_DETAILS_FAILED";

const initialState = {
        broker: {},
        error: false,
        loading: false
}

export const getOfferDetailsData = (offerId) => {
    return (dispatch) => {
        dispatch(getListActions.started());

        OfferDetailsService.getOfferDetails(offerId)
            .then((response) => {
              ////console.log("response", response.data);
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const offerDetailsReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case OFFER_DETAILS_STARTED: {
            newState = update.set(state, 'loading', true);
            break;
        }
        case OFFER_DETAILS_SUCCESS: {
            newState = update.set(state, 'loading', false);
            newState = update.set(newState, 'broker', action.payload);
            break;
        }
        case OFFER_DETAILS_FAILED: {
            newState = update.set(state, 'loading', false);
            newState = update.set(newState, 'error', true);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {
        return {
            type: OFFER_DETAILS_STARTED
        }
    },
    success: (data) => {
      ////console.log("DATA : ",data);
        return {
            type: OFFER_DETAILS_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {
        return {
            type: OFFER_DETAILS_FAILED
        }
    }
}



