import axios from 'axios';
import {serverUrl} from "../../../config";

export default class ProfileService{
  static getBrokerProfile(){
    const url = `${serverUrl}api/broker/account/get-profile`;
    return axios.get(url);
  }

  static editBrokerPassword(oldPassword, newPassword){
    const data = {oldPassword, newPassword};
    const url = `${serverUrl}api/broker/account/change-password`;
    return  axios.post(url, data);
  }

  static editBrokerProfile(region,email,phoneNumber,companyName, customsControlZones, legalAddress){
    const data = {region,email,phoneNumber,companyName, customsControlZones, legalAddress};
    const url = `${serverUrl}api/broker/account/edit-profile`;
    return axios.post(url, data);
  }
  static changeSendEmailStatus(sendEmailDisabled){
    const data = {sendEmailDisabled};
    const url = `${serverUrl}api/broker/account/change-send-email-status`;
    return axios.post(url, data);
  }
  static changeImage(data){
    const url=`${serverUrl}api/broker/account/change-image`;
    return axios.post(url, data);
  }
}
