import common from "./common.json";
import navbar from "./navbar.json";
import footer from "./footer.json";
import home from "./home.json";

export default {
    common,
    navbar,
    footer,
    home,
}