import axios from 'axios';
import {serverUrl} from '../../../config';

export default class PermissionConfirmedService{
    static getLicense(brokerId){
        const url=`${serverUrl}api/admin/broker/license/${brokerId}`;
        return axios.get(url);
    };
    static ConfirmedPermission(brokerId){
      const url=`${serverUrl}api/admin/broker/confirmed/${brokerId}`;
      return axios.get(url);
  };
}
