import ArchiveOfContractsService from "./ArchiveOfContractsService";
import update from '../../../helpers/update';


export const ARCHIVE_CONTRACTS_SUCCESS = "ARCHIVE_CONTRACTS_SUCCESS";
export const ARCHIVE_CONTRACTS_STARTED = "ARCHIVE_CONTRACTS_STARTED";
export const ARCHIVE_CONTRACTS_FAILED = "ARCHIVE_CONTRACTS_FAILED";

export const CONTRACT_DETAILS_SUCCESS = "CONTRACT_DETAILS_SUCCESS";
export const CONTRACT_DETAILS_STARTED = "CONTRACT_DETAILS_STARTED";
export const CONTRACT_DETAILS_FAILED = "CONTRACT_DETAILS_FAILED";


const initialState = {
    list: {
        data: [],
        error: false,
        loading: false
    },

    details: {
      data: {},
      error: false,
      loading: false
    },
    year: '',
    month: ''
}

export const getArchiveOfContractsData = (filter) => {
    return (dispatch) => {
        dispatch(getListActions.started());
        ArchiveOfContractsService.getArchiveOfContracts(filter)
            .then((response) => {
                dispatch(getListActions.success(response.data));
            })
            .catch(() => {
                dispatch(getListActions.failed());
            });
    }
}

export const getContractDetailsData = (filter) => {
  return (dispatch) => {
      dispatch(getDetailsActions.started());
      ArchiveOfContractsService.getContractDetails(filter)
          .then((response) => {
              dispatch(getDetailsActions.success(response.data));
          })
          .catch(() => {
              dispatch(getDetailsActions.failed());
          });
  }
}

export const archiveOfContractsReducer=(state=initialState, action) =>{

    let newState = state;
    switch (action.type) {
        case ARCHIVE_CONTRACTS_STARTED: {
            newState = update.set(state, 'list.loading', true);
            break;
        }
        case ARCHIVE_CONTRACTS_SUCCESS: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.data', action.payload.contracts);
            break;
        }
        case ARCHIVE_CONTRACTS_FAILED: {
            newState = update.set(state, 'list.loading', false);
            newState = update.set(newState, 'list.error', true);
            break;
        }
        case CONTRACT_DETAILS_STARTED: {
          newState = update.set(state, 'details.loading', true);
          break;
        }
        case CONTRACT_DETAILS_SUCCESS: {
          newState = update.set(state, 'details.loading', false);
          newState = update.set(newState, 'details.data', action.payload);
          break;
        }
        case CONTRACT_DETAILS_FAILED: {
          newState = update.set(state, 'details.loading', false);
          newState = update.set(newState, 'details.error', true);
          break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const getListActions = {
    started: () => {

        return {
            type: ARCHIVE_CONTRACTS_STARTED
        }
    },
    success: (data) => {

        return {
            type: ARCHIVE_CONTRACTS_SUCCESS,
            payload: data
        }
    },
    failed: (error) => {

        return {
            type: ARCHIVE_CONTRACTS_FAILED
        }
    }
}

export const getDetailsActions = {
  started: () => {

      return {
          type: CONTRACT_DETAILS_STARTED
      }
  },
  success: (data) => {
////console.log("getDetailsActions :", data);
      return {
          type: CONTRACT_DETAILS_SUCCESS,
          payload: data
      }
  },
  failed: (error) => {

      return {
          type: CONTRACT_DETAILS_FAILED
      }
  }
}



