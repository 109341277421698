import axios from "axios";
import {serverUrl} from "../../../config"
export default class ContractInfoService{
    static getContractInfo(contractId){
        return axios.get(`${serverUrl}api/broker/home/get-proposition-and-contract-info/${contractId}`)
    }

    static getConfirmedContractInfo(contractId){
        return axios.get(`${serverUrl}api/broker/home/get-confirmed-contract-info/${contractId}`)
    }
   
}
