import axios from 'axios';
import {serverUrl} from "../../../config";
export default class ConfirmationService{
  static getAdvert(advertId){
    const url = `${serverUrl}api/broker/home/get-advert/` + advertId;
    return axios.get(url)

  }
  static getOfferTypes(){
    const url = `${serverUrl}api/broker/Home/get-offer-types`;
    return axios.get(url);
  }
  static sendOffer(confirmation){
    const url = `${serverUrl}api/broker/Home/send-offer`;
    return axios.post(url, confirmation);
  }

}
