import axios from 'axios';
import { serverUrl } from '../config';

export default class BellService{
    static getBrokerMessages() {
      const url = `${serverUrl}api/broker/bell`;
      return axios.get(url);
    };
    static getClientMessages() {
      const url = `${serverUrl}api/client/bell`;
      return axios.get(url);
    }
    static getUnconfirmedContracts(){
     return axios.get(`${serverUrl}api/client/account/get-unconfirmed-count`);
    }
    static getConfirmedContracts(){
      return axios.get(`${serverUrl}api/client/account/get-confirmed-count`);
     }
    static getAdvertsCount() {
      const url = `${serverUrl}api/broker/bell/counter`;
      return axios.get(url);
    }
}
