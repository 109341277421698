import update from '../../../../helpers/update';
import AddAdvertService from "./addAdvertService";
import updateFromLibrary from 'immutability-helper';

export const ADD_IMAGE_STARTED = "add_advert/ADD_IMAGE_STARTED";
export const ADD_IMAGE_SUCCESS = "add_advert/ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAILED = "add_advert/ADD_IMAGE_FAILED";

export const DELETE_IMAGE_STARTED = "add_advert/DELETE_IMAGE_STARTED";
export const DELETE_IMAGE_SUCCESS = "add_advert/DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILED = "add_advert/DELETE_IMAGE_FAILED";
export const NULLIFY_IMAGES = "NULLIFY_IMAGES";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const CREATE_ADVERT_STARTED = "add_advert/CREATE_ADVERT_STARTED";
export const CREATE_ADVERT_SUCCESS= "add_advert/CREATE_ADVERT_SUCCESS";
export const CREATE_ADVERT_FAILED = "add_advert/CREATE_ADVERT_FAILED";

const initialState = {
    advert: {
        images: [],
        description: {},
        data: {},
        isLoading: false,
        isError: false,
        errMsg: ''
    },
}

export const addAdvertReducer = (state = initialState, action) => {
    let newState = state;
    switch (action.type) {
        case ADD_IMAGE_STARTED: {
            newState = update.set(state, 'advert.isLoading', true);
            break;
        }
        case ADD_IMAGE_SUCCESS: {
            newState = update.set(state, 'advert.isLoading', false);
            newState = updateFromLibrary(newState, {
                    advert: {
                        images: {
                            $push: [action.image]
                        }
                    }
                });
            break;
        }
        case ADD_IMAGE_FAILED: {
            newState = update.set(state, 'advert.isLoading', false);
            newState = update.set(newState, 'advert.isError', true);
            newState = update.set(newState, 'advert.errMsg', action.errMsg);
            break;
        }

        case DELETE_IMAGE_STARTED: {
            newState = update.set(state, 'advert.isLoading', true);
            break;
        }
        case DELETE_IMAGE_SUCCESS: {
            newState = update.set(state, 'advert.isLoading', false);
            newState.advert.images = newState.advert.images.filter(item => item.id !== action.id);
            break;
        }
        case DELETE_IMAGE_FAILED: {
            newState = update.set(state, 'advert.isLoading', false);
            newState = update.set(newState, 'advert.isError', true);
            newState = update.set(newState, 'advert.errMsg', action.errMsg);
            break;
        }
        case SHOW_LOADER: {
            newState = update.set(state, 'advert.isLoading', true);
            break;
        }
        case HIDE_LOADER: {
            newState = update.set(state, 'advert.isLoading', false);
            break;
        }
        case NULLIFY_IMAGES: {
            newState = update.set(state, 'advert.images', []);
            break;
        }
        default: {
            return newState;
        }
    }
    return newState;
}

export const addImage = (data) => {
    console.log("dt",data);
    return (dispatch) => {
        dispatch(getListActionsToAddImage.started());
        AddAdvertService.addImage(data)
            .then(response => {
                dispatch(getListActionsToAddImage.success(response.data));
            })
            .catch(error => {
                dispatch(getListActionsToAddImage.failed(error.response.data));
            });
    }
}

export const deleteImage = (id) => {
    return (dispatch) => {
        dispatch(getListActionsToDeleteImage.started());
        AddAdvertService.deleteImage(id)
            .then(() => {
                dispatch(getListActionsToDeleteImage.success(id));
            })
            .catch(error => {
                dispatch(getListActionsToDeleteImage.failed(error.response.data));
            });
    }
}

export const showLoader = () => {
    return (dispatch) => {
        dispatch(getListActionsToLoader.started());
    }
}

export const hideLoader = () => {
    return (dispatch) => {
        dispatch(getListActionsToLoader.stoped());
    }
}

export const nullifyImages = () => {
    return (dispatch) => {
        dispatch(getListActionsToDeleteImage.nullify());
    }
}

export const getListActionsToAddImage = {
    started: () => {
        return {
            type: ADD_IMAGE_STARTED
        }
    },
    success: (data) => {
        return {
            type: ADD_IMAGE_SUCCESS,
            image: data
        }
    },
    failed: (error) => {
        return {
            type: ADD_IMAGE_FAILED,
            errMsg: error
        }
    }
}

///////////////////////////////////////////
export const getListActionsToCreateAdvert = {
  started: () => {
      return {
          type: CREATE_ADVERT_STARTED
      }
  },
  success: (data) => {
      return {
          type: CREATE_ADVERT_SUCCESS,
          advert: data
      }
  },
  failed: (error) => {
      return {
          type: CREATE_ADVERT_FAILED,
          errMsg: error
      }
  }
}

export const getListActionsToDeleteImage = {
    started: () => {
        return {
            type: DELETE_IMAGE_STARTED
        }
    },
    success: (data) => {
        return {
            type: DELETE_IMAGE_SUCCESS,
            id: data
        }
    },
    failed: (error) => {
        return {
            type: DELETE_IMAGE_FAILED,
            errMsg: error
        }
    },
    nullify: () => {
        return {
            type: NULLIFY_IMAGES
        }
    }
}

export const getListActionsToLoader = {
    started: () => {
        return {
            type: SHOW_LOADER
        }
    },
    stoped: () => {
        return {
            type: HIDE_LOADER
        }
    }
}
