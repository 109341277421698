import axios from "axios";
import { serverUrl } from "../../../../config";
export default class AdvertDetailsService {
  static getOffers(id) {
    const url = `${serverUrl}api/client/account/getAdvert-offers`;
    const data = {
      advertId: id
    };
    return axios.post(url, data);
    //return Axios.get("api/item/products/" + pageNumber);
  }
  static getApprovedOfferInfo(id) {
    const url = `${serverUrl}api/client/account/get-approved-offer-info/${id}`;
    return axios.get(url);
  }
  static getContractInfo(id) {
    const url = `${serverUrl}api/client/account/get-contract-info/${id}`;
    return axios.get(url);
  }
  static getAdvertImages(id) {
    const url = `${serverUrl}api/client/account/getAdvert-images`;
    const data = {
      advertId: id
    };
    return axios.post(url, data);
  }
  static getAdvertDescription(id) {
    const url = `${serverUrl}api/client/account/getAdvertDescription`;
    const data = {
      advertId: id
    };
    return axios.post(url, data);
  }
  static changeImage(model) {
    const url = `${serverUrl}api/client/account/change-advert-image`;
    return axios.post(url, model);
  }
  static getDocument(id, isContract){
    const model={
      id: id, 
      isContract: isContract
    } 

    const url = `${serverUrl}api/broker/Home/contract-document`;
    return axios.post(url, model);


  }
  static confirmDocument (contractId){
    const url = `${serverUrl}api/client/account/confirm-document/${contractId}`;
    return axios.get(url);
  }

  //////////////////////////////////////////////////////
  // static changeAdvertImage(imageId, image) {
  //   const url = `${serverUrl}api/client/account/change-advert-image`;
  //   return axios.post(url, { imageId, image });
  // }
}
